@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-page-setting-images {
  /* width: 30px; */
  height: 75px;
  margin: 3px;
  margin-top: 8px;
  background-color: grey;
  padding: 2px;
  border: 1px solid black;
  object-fit: contain;
  max-width: 125px;
}

.row-remove {
  animation: row-remove .5s 1 forwards !important;
  transform: scaleY(1);
  transform-origin: center 0;
  display: table-row;
  background: rgb(211, 122, 122) !important;
}

@keyframes row-remove {
  0% {
    transform: scaleY(0.9);
    background: rgb(211, 122, 122) !important;
  }

  100% {
    transform: scaleY(0);
    background: rgb(208, 20, 20) !important;
  }
}

#aside {
  position: fixed;
}

body {
  font-size: 80% !important;
}

/* side bar hiding functionallity */
#aside::-webkit-scrollbar {
  width: 0;
}

#aside::-webkit-scrollbar-thumb {
  width: 0;
}

#aside.hide-sidebar {
  position: fixed;
  transform: translateX(-100%);
  transition: 0.4s ease-in-out;
}

main.hide-sidebar {
  transition: 0.4s ease-in-out;
  margin-left: 0 !important;
}

main,
#aside {
  transition: 0.4s ease-in-out;
}

/* #aside {
  overflow: auto;
} */
#aside {
  overflow: inherit;
  width: 60px;
  z-index: 999;
  margin-top: 71px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

div#aside i {
  color: #008000 !important;
  padding: 8px;
  border-radius: 4px;
  transition: .3s;
  cursor: pointer;
}

div#aside i:hover {
  color: #fff !important;
  background: #008000 !important;
}

.subGroupMenuItems {
  position: absolute;
  left: 59px;
  top: 0px;
  border-radius: 10px;
  width: 200px !important;
  background: #fff;
  z-index: 999;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.subGroupMenuItems p {
  color: #000000 !important;
}

.lll:hover {
  color: red;
}

.lll.active {
  color: red !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-item .nav-link .icon {
  color: rgb(28, 27, 27) !important;
  font-size: 20px !important;
}

.nav-item .nav-link.active .icon {
  color: white !important;
}

.mh-100vh {
  min-height: 99vh !important;
}

.pagination {
  float: right;
  padding: 0 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .pages ul {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 10px 0px 0px;
}

.pagination .pages ul li {
  margin: 0;
}

.pagination .pages ul li:nth-child(1) a,
.pagination .pages ul li:last-child a {
  width: auto;
  /* padding: 0 10px; */
  padding-bottom: 3px;
  margin: 0 10px;
  text-transform: capitalize;
  background: none;
  border-radius: none;
  color: grey;
  font-size: 15px !important;
}

.pagination .pages ul li:nth-child(1) a:hover,
.pagination .pages ul li:last-child a:hover {
  background: transparent;
  color: black;
}

.pagination .pages ul li.selected a {
  background: #19632f;
  color: #fff;
}

.pagination .pages ul li.disabled a {
  cursor: not-allowed;
}

.pagination .pages ul li.disabled a:hover {
  color: grey;
  font-weight: normal;
}

.pagination .pages ul li a:hover {
  background: #19632f;
  color: #fff;
}


.pagination .pages ul li a {
  min-width: 25px !important;
  height: 25px !important;
  margin: 0 3px;
  background: grey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px !important;
  cursor: pointer;
  line-height: 1;
}

.pagination .pages ul li.break a {
  color: black;
  background: transparent;
}

#outer-main-content {
  width: 100%;
  height: 100vh !important;
  overflow: hidden;
}

.innerBox {
  width: calc(100% - 61px);
  float: right !important;
  padding-left: 0px !important;
  /* position: relative; */
  margin-top: 72px;
}

.addCountryPage {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.addNewBtn {
  padding: 10px 20px;
  background: #1a7335;
  color: #ffffff;
  border-radius: 5px;
}

.addCountryPage .col-6 {
  width: 45%;
  height: 100%;
  margin: 0;
  border: 0;
}

/* sidebar css */
.card-header.flexed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  transform: translateX(100%);
  background: red;
  transition: 0.25s;
}

.sidebar.active {
  transform: translateX(0);
}

.reminder-menu {
  background: orangered;
  color: white;
  font-weight: bolder;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.reminder-menu .resend {
  text-transform: lowercase;
  text-decoration: underline;
  margin: 0 10px;
  cursor: pointer;
}

.reminder-menu .resend:hover {
  color: black;
}

/* csv design */

.screen {
  width: 100%;
  max-width: 100%;
  overflow: auto;
}

.screen>div:nth-child(1) {
  max-width: 20%;
  min-width: 20%;
  min-height: calc(100vh - 60px);
  margin-right: 10px;
}

.screen>div:nth-child(2) {
  background: white;
  max-width: 100%;
  min-width: 100%;
  min-height: calc(100vh - 60px);
  overflow: auto;
  padding: 20px;
}

.screen>div:nth-child(2) td.booldata {}

.screen>div:nth-child(2) td.num {}

.collegeList {
  z-index: 99999 !important;
  max-width: 100%;
}

.required_missing {
  background: rgba(255, 0, 0, 0.341) !important;
}

.required_notation {
  border-bottom: 3px solid red !important;
  color: red;
}

.tableData td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 100px; */
  cursor: pointer;
  border: 1px solid grey;
}

.tableData {
  height: 500px;
  overflow: auto;
  z-index: 99;
  margin: 20px 0px 0px 20px;
}

aside.hide_now {
  display: none !important;
}

main.full_screen {
  margin-left: 0 !important;
}

.dashboardItem {
  margin: 10px;
  border-radius: 10px;
  width: 23%;
  padding: 15px;
  text-align: right;
  position: relative;
  background: #fbfafa;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */

  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px;

  cursor: pointer;
}

.dashboardItem:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.dashboardItem h5 {
  color: #2a276b;
  font-size: 170%;
  font-weight: bolder;
}

.dashboardItem p {
  font-weight: normal;
  margin-bottom: 0;
  padding-bottom: 0;
  font-weight: bold;
}

.minHeight {
  min-height: 100vh;
}

.dashboardItem i {
  position: absolute;
  top: 5px;
  left: 5px;
  transform: translate(20%, 20%);
  width: 40px;
  height: 40px;
  font-size: 100%;
  color: white;
  background: rgb(84, 174, 84);
  /* background: #2a276b; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.dashboardItemsList {
  display: flex;
  flex-wrap: wrap;
}

main .navbar {
  border-radius: 0 !important;
}

main .container-fluid {
  padding: 0 !important;
  margin: 0 !important;
}

.p-45 {
  padding-left: 40px;
  padding-right: 40px;
}

.tableIcons i {
  box-shadow: none !important;
  color: white;
  font-size: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 2px;
}

.tableIcons:hover .fa-edit {
  background: rgba(10, 7, 68, 0.914);
}

.tableIcons .fa-edit {
  background: #2a276b;
}

.tableIcons .fa-trash-can {
  background: red !important;
}

.tableIcons:hover .fa-trash-can {
  background: rgb(172, 5, 5) !important;
}

.hover-underline:hover {
  cursor: pointer;
}

.AddDataBtn {
  margin: 5px 0 5px 10px;
  background: #2a276b;
  color: white;
}

.AddDataBtn:hover {
  background: #2a276b;
  color: white;
}

.statusCell {
  position: relative;
}

.statusCell>span {
  cursor: pointer;
  background: #1a7335;
  color: #fff;
}

.statusCell>span:hover,
.statusCell>span:focus {
  background: #1a7335;
}

.statusCell>span.active {
  background: grey;
  color: black;
}

.statusBox {
  background: rgb(226, 221, 221);
  position: absolute;
  right: 0;
  top: 85%;
  display: none;
  z-index: 999;
  border-radius: 10px;
}

td.max-width {
  max-width: 400px !important;
}

.statusBox.active {
  display: flex;
}

.statusBox ul {
  list-style: none;
  width: 100%;
  padding: 10px;
  margin: 0;
}

.statusBox ul li {
  width: 100%;
  font-size: 90%;
  text-align: left;
  padding: 5px;
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
}

.statusBox ul li:nth-last-child(1) {
  border-bottom: none !important;
}

.LogoLoginScreen {
  max-width: 100%;
  width: 50%;
}

.loginSelectionDiv {
  width: 100%;
  min-height: 100vh;

  align-items: center;
  /* margin-top: 40px;
  flex-direction: column; */
}

.loginSelectionDiv>.row {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.main-login-box {
  width: 100%;
}

.loginSelectionDiv>.row .card {
  width: 30%;
  min-width: 30%;
  border: none !important;
  box-shadow: none !important;
  padding: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin: 5px;
}

.loginSelectionDiv>.row .card:hover {
  background: #2a276b73;
}

.loginSelectionDiv>.row .card.active {
  background: #2a276b;
  color: white;
}

.bg-gradient-primary {
  background: #2a276b !important;
}

.nav-item .nav-link.active {
  background: #2a276bca !important;
}

.collegeFilters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 0;
  flex-wrap: wrap;
}

.collegeFilters .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.collegeFilters .filter-group {
  display: flex;
  flex-direction: column;
  /* width: 300px; */
}

.dropZone {
  width: 35%;
  height: 300px;
  /* background: rgba(128, 128, 128, 0.582); */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 20px;
  color: black;
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border: 1px solid #ededed;
}

.dropZone p {
  font-size: 22px;
  margin-bottom: 15px;
}

.dropZone button {
  padding: 10px 30px;
  font-size: 15px;
  transition: all .4s;
}

.dropZone button {
  border-radius: 50px !important;
  text-transform: capitalize !important;
  padding: 7px 15px;
  color: white;
  border: none;
}

.dropZone button:hover {
  background: #1a7335d1 !important;
}

.filesList {
  width: 65%;
  list-style-type: none;
  margin: 0 auto;
}

.filesList .fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doubleProgramRow {
  background-color: red !important;
}

.invalid-feedback {
  color: red !important;
}

.header-bg-dark #header_menu {
  background: #181739;
  /* background: transparent !important; */
}

.permissions.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 70vh;
  background: white;
  z-index: 9999999999999;
  overflow: auto;
}

.permissions.popup>ol {
  width: 100%;
  overflow: auto;
}

.permissions.popup>ol li {}

.permissions.popup>ol li:nth-child(1) label {
  margin-top: 0 !important;
}

.permissions.popup>ol li label {
  color: #000;
  display: flex;
  font-weight: bolder;
  font-size: 130%;
  background: rgb(169 169 169 / 14%);
  padding: 5px;
  width: 100% !important;
  margin-top: 20px;
}

.permissions.popup h2 {
  font-weight: bolder;
  font-size: 150%;
  background: #2a276b;
  color: white;
  padding: 10px;
}

.roleStyle {
  background: rgba(31, 173, 62, 0.612);
  text-align: center;
  padding: 10px 8px;
  /* border: 1px solid white; */
  width: 100%;
  margin: 0 auto;
  /* border-radius: 10px; */
  position: relative;
  font-size: 20px;
  /* padding-bottom: 20px; */
  /* margin-bottom: 20px; */
}

.roleStyle span {
  color: white;
  font-weight: bolder;
  text-transform: capitalize;
}

.roleStyle p {
  position: absolute;
  font-size: 80%;
  width: 60%;
  text-align: center;
  background: white;
  color: black;
  padding: 3px 10px;
  border-radius: 10px;
  left: 50%;
  bottom: -50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  text-transform: uppercase;
}

.loginTabs span {
  cursor: pointer;
  color: #fff !important;
  background: black;
  width: 50%;
  margin: 0;
  border-radius: 0;
}

.loginTabs span.active {
  color: #fff !important;
  width: 50%;
  background: #1a7335;
}

.loginTabs {
  width: 100%;
  max-width: 500px;
  margin: 0;
}

.socialBtns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.googleBtn {
  margin: 5px;
  border-radius: 2px;
  padding: 5px 5px;
  background: rgb(25, 65, 168);
  border: 1px solid rgb(25, 65, 168);
  color: white;
  font-weight: bolder;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: lighter;
}

.googleBtn:hover {
  background: rgba(25, 66, 168, 0.813);
}

.googleBtn img {
  position: absolute;
  left: 0;
  height: 100%;
  background: white;
  padding: 10px;
}

/* 
.checkboxstyle {
  width: 20px;
  height: 20px;
}
.checkboxstyle::before{
} */

.sidebaritem {
  position: relative;
}

.sidebaritem .count {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(120%, -50%);
  width: 25px;
  height: 25px;
  background: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

footer {
  background: rgb(168, 166, 166);
  margin-top: 30px;
}

/* asidebar style */
.agentDetailsAsideBar {}

.tabs {
  display: flex;
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid #e5e5e5;
  background: #21202014;
}

.tabs h2 {
  min-width: 80px;
  text-align: center;
}

.tabDetail {
  display: none;
}

.tabDetail.active {
  display: flex;
  flex-direction: column;
}

.tabs h2 {
  padding: 10px;
  cursor: pointer;
}

.tabs h2.active {
  background: #2a276b !important;
  color: white !important;
}

.tabs h2:hover {
  background: #15134430;
}

.agentDetailsAsideBar.active {
  right: 0;
}

.agentDetailsAsideBar h1 {
  font-weight: bolder;
  padding: 10px;
  text-transform: uppercase;
  border-bottom: 1px solid white;
  background: #2a276b;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.agentDetailsAsideBar h1 button {
  text-transform: uppercase;
  background: red;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 80%;
}

.agentDetailsAsideBar h1 button:hover {
  background: darkred;
}

.agentDetailsAsideBar table {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 0;
  border-radius: 10px;
}

.agentDetailsAsideBar table table {
  margin: 0;
}

.agentDetailsAsideBar table table tbody {
  margin: 20px 0;
}

.agentDetailsAsideBar table .tabDetail table {
  padding: 0;
  box-shadow: none;
}

.agentDetailsAsideBar table .tabDetail table tbody {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.agentDetailsAsideBar table tr {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  justify-content: space-between;
}

.agentDetailsAsideBar table tr>div {
  width: 48%;
  border-bottom: 1px solid #ededed;
  flex-direction: row !important;
  justify-content: space-between;
}

.agentDetailsAsideBar table tr th {
  padding-right: 10px;
  color: black;
  text-align: left;
  width: fit-content;
  max-width: 100%;
  font-size: 16px;
}

.student-table .agentDetailsAsideBar table {
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.Verify_status {
  color: rgb(76, 76, 76);
  font-weight: bolder;
  margin: 0 10px;
}

.approved_status {
  color: rgb(43, 137, 43);
  font-weight: bolder;
  margin: 0 10px;
}

.declined_status {
  color: red;
  font-weight: bolder;
  margin: 0 10px;
}

.downloadSvg {
  margin: 0 10px;
  cursor: pointer;
}

.downloadSvg:hover {
  fill: grey;
}

.agentDetailsAsideBar table tr td {
  width: fit-content;
  max-width: 100%;
  font-size: 14px;
  word-break: break-all;
}

.agentDetailsAsideBar table tr .question {
  width: fit-content;
  padding: 0 10px;
  display: block;
  color: #000;
  font-weight: bold;
  font: 80%;
  font-size: 16px;
}

.agentDetailsAsideBar .table-responsive .user {
  background-color: #008000;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  padding: 10px;
}

.agentDetailsAsideBar .table-responsive .user svg {
  width: 30px;
  height: 30px;
  color: #fff;
}

.agentDetailsAsideBar table tr .answer {
  display: block;
  padding: 0 10px;
  width: fit-content;
  font-size: 16px;
}

.agentDetailsAsideBar table .question_row {
  display: flex;
  flex-direction: row;
  width: 48%;
  margin: 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
}

.agentDetailsAsideBar>div {
  position: relative;
}

.agentDetailsAsideBar .tabDetail {
  /* height: 100vh; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row !important;
  justify-content: space-between;
  gap: 5px
}

.agentDetailsAsideBar .tabDetail img {
  width: 40px;
  height: 30px;
  margin-right: 20px;
}

.user-details p {
  font-size: 15px;
}


.tabDetail table tbody tr.docRow {
  border-bottom: 1px solid #ced4da !important;
  padding: 0;
}

.tabDetail table tbody tr.docRow:last-child {
  border-bottom: none !important;
}

.tabDetail table tbody tr.docRow td {
  margin: 0 !important;
}

.tabDetail table tbody tr.docRow td:first-child {
  font-weight: bold;
  font-size: 16px;
}

.tabDetail table tbody tr.docRow:first-child td {
  border-top: 1px solid #ced4da !important;
}

.tabDetail table tbody tr.docRow:nth-child(even) td {
  background: none;
}

.agentDetailsAsideBar.enrolled .tabDetail table tbody tr.docRow td:first-child,
.agentDetailsAsideBar.enrolled .agentDetailsAsideBar table tr td {
  font-size: 14px;
}

.agentDetailsAsideBar.enrolled table .tabDetail table tbody {
  gap: 0;
  margin: 8px 0 0;
}

.agentDetailsAsideBar .tabDetail td button {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer;
}

button.approve {
  background: green;
  color: white;
}

.agentDetailsAsideBar .tabDetail td button:hover {
  filter: brightness(0.8);
}

button.decline {
  background: red;
  color: white;
}

.agentDetailsAsideBar .tabDetail .profile-image {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.357);
  color: white;
  margin-top: 40px;
  position: relative;
  border: 2px solid black;
  /* padding: 10px; */
  cursor: pointer;
}

.agentDetailsAsideBar .tabDetail .profile-image:hover {
  box-shadow: 0 0 10px -2px black;
}

.agentDetailsAsideBar .tabDetail .company-logo {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.357);
  color: white;
  margin-top: 40px;
  position: relative;
  border: 2px solid black !important;
  /* padding: 10px !important; */
  cursor: pointer;
}

.agentDetailsAsideBar .tabDetail .company-logo:hover {
  box-shadow: 0 0 10px -2px black;
}

.agentDetailsAsideBar .tabDetail .certificate-pdf {
  position: relative;
  width: 90%;
  background: #2a276b;
  margin: 10px;
  margin-top: 40px !important;
  padding: 20px 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.agentDetailsAsideBar .tabDetail label {
  position: absolute;
  top: 0%;
  left: 0;
  transform: translateY(-100%);
  color: #2a276b;
  font-weight: bolder;
}

.agentDetailsAsideBar .tabDetail .certificate-pdf span {
  padding: 2px 10px;
  border-radius: 20px;
  border: 1px solid white;
  cursor: pointer;
}

.agentDetailsAsideBar .tabDetail .certificate-pdf span:hover {
  background: whitesmoke;
  color: black;
}

.top-notification-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
}

.top-notification-box button {
  padding: 5px 20px;
  text-transform: capitalize;
  background: #2a276b;
  color: white;
  border-radius: 10px;
}

.top-notification-box button:hover {
  background: #19173f;
}

.notificationDiv {
  display: flex;
  flex-direction: row;
  background: rgba(128, 128, 128, 0.264);
  margin: 10px;
  border-radius: 5px;
  position: relative;
  border: 2px solid transparent;
  padding: 20px 10px;
}

.notificationDiv .unreadBox {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 10px;
  color: red;
}

.notificationDiv.unread .unreadBox {
  display: flex;
}

.notificationDiv .left {
  width: 50%;
}

.notificationDiv .left h2 {
  font-weight: bold;
  cursor: pointer;
}

.notificationDiv .left h2:hover {
  text-decoration: underline !important;
}

.notificationDiv .right {
  width: 50%;
  text-align: right !important;
}

.notificationDiv .left p {
  color: grey;
}

.notificationDiv .tabDetail {
  width: auto;
  height: 100%;
  display: flex;
  font-size: 90%;
  padding: 5px 10px;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: bolder;
  letter-spacing: 0.5px;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  color: grey;
}

.remarkpopup {
  width: 400px;
  /* min-height: 250px; */
  background: #2a276b;
  z-index: 999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px -2px black;
  color: white;
  font-size: 15px;
  padding: 20px;
  display: none;
}

.remarkpopup.active {
  display: flex;
}

.remarkpopup h1 {
  width: 100%;
  font-weight: bolder;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remarkpopup h1 svg {
  fill: red;
  cursor: pointer;
}

.remarkpopup h1 svg:hover {
  fill: rgb(219, 42, 42);
}

.submitRemarkBtn {
  background: white;
  color: #000;
}

.remarkpopup ul {
  margin: 10px 0;
}

.remarkpopup ul label {
  cursor: pointer;
}

.remarkpopup .form-group:nth-child(3) {
  margin-bottom: 20px;
}

.remarkpopup .form-group input {
  margin-right: 10px;
  color: black;
}


.subItemIconDot {
  width: 10px !important;
  height: 10px !important;
  background: #000;
  border-radius: 50%;
  position: relative;
}

#aside .active-link {
  background: rgba(128, 128, 128, 0.334);
}

#aside .active-link button,
#aside .active-link button .text-base {
  color: white !important;
}

#aside .active-link-group>button {
  background: rgba(255, 255, 255, 0.207);
}

#aside .defaultShowMenu {
  display: flex !important;
}

#aside .default-rotate-0 {
  transform: rotate(0) !important;
}

@keyframes openItem {
  0% {
    display: block;
    height: 0%;
  }

  50% {
    height: 50%;
  }

  100% {
    height: 100%;
  }
}

#aside .subGroupMenuItems.hidden-now {
  display: none !important;
  /* animation: closeItem 0.25s linear 1 forwards; */
}

#aside .defaultShowMenu {
  display: flex !important;
}

#aside .default-rotate-0 {
  transform: rotate(0) !important;
}

#aside .subGroupMenuItems {
  display: block;
  max-height: 150px;
  overflow-y: scroll;
  width: 235px !important;
}

#aside .subGroupMenuItems::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#aside .subGroupMenuItems::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 2px;
}


@media screen and (max-width: 960px) {
  .overlay.show {
    display: block !important;
  }

  #aside.show {
    transform: translateX(-0%) !important;
    -webkit-transform: translateX(-0%) !important;
    -moz-transform: translateX(-0%) !important;
    -ms-transform: translateX(-0%) !important;
    -o-transform: translateX(-0%) !important;
  }

  main {
    margin-left: 0 !important;
    transition: 0.5s;
  }

  /* 
  #aside {
    z-index: 10005;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%) !important;
    -webkit-transform: translateX(-100%) !important;
    -moz-transform: translateX(-100%) !important;
    -ms-transform: translateX(-100%) !important;
    -o-transform: translateX(-100%) !important;
  } */
}

@keyframes closeItem {
  0% {
    height: 100%;
  }

  50% {
    height: 50%;
  }

  100% {
    height: 0%;
    /* display: none !important; */
  }
}

.notificationPopup {
  cursor: pointer;
}

.notificationPopup:hover {
  color: red;
}

/* new */
.designedTable tr th {
  background: transparent;
  color: #000;
  padding: 10px 5px;
}

.designedTable tr th:nth-last-child(1) {
  background: none;
}

.designedTable tr th {
  border: 1px solid #e5e2e2;
}

.designedTable tr td {
  border: 1px solid #ddd;
}

.designedTable tr:nth-child(even) {
  background: #e5e2e2;
}

/* 16 feb 2023 */

.collegeFilters .filter-group select,
.collegeFilters .filter-group input {
  border-radius: 5px;
  border: 1px solid #ccc !important;
  color: #999;
  outline: none;
  font-size: 12px;
  padding: 10px 10px;
  background: #fff;
}

.addCountryPage .card-body input,
.addCountryPage .card-body .css-13cymwt-control,
.addCountryPage .card-body select {
  border-radius: 5px;
  border: 1px solid rgb(156, 155, 155) !important;
  outline: none;
  font-size: 14px;
  background-color: #fff;
}

.addCountryPage .card-body .css-13cymwt-control input {
  border: none !important;
}

.dashbord-table tr {
  border-bottom: 10px solid #fff;
}

/* new */
.intakestable {
  background: whitesmoke;
}

.intakestable th,
.intakestable td {
  padding: 10px;
}

.intakestable th {
  font-weight: bolder;
  border: 1px solid grey;
}

.intakestable td.col {
  font-weight: bolder;
  color: green !important;
  border: 1px solid grey;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.intakestable td {
  border: 1px solid grey;
  position: relative;
}

.intakestable td input[type="checkbox"] {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.intakestable .deleteYear {
  min-width: 20px !important;
  min-height: 20px !important;
  background: red;
  border-radius: 50%;
  margin-right: 20px;
  color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}

.intakestable td.col:hover .deleteYear {
  display: flex;
}

.intakestable .deleteYear:hover {
  background: rgb(99, 3, 3);
}

.intakestable .intakeStatus {
  width: 10px;
  height: 10px;
  /* background: red; */
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.intakestable .intakeStatus.active {
  color: green;
}

.intakestable .intakeStatus.deactive {
  color: red;
}

/* data */
.select-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: content-box;
  padding: 5px 0;
}

.select-checkbox option {
  height: 30px;
}

.option-selected.vissible {
  background: red;
}

.filter-tab {
  display: none;
}

.filter-tab.active {
  display: block;
}

.filter-tab-title {
  cursor: pointer;
}

.filter-tab-title.active {
  background: rgba(128, 128, 128, 0.445);
}

.createcollegeNamePopup.documents {
  height: fit-content;
}

.createcollegeNamePopup.active {
  display: block;
}

.createcollegeNamePopup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  z-index: 9999;
}

.createcollegeNamePopup>label {
  background: #2a276b;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 30px;
  border-radius: 10px 10px 0 0;
  color: white;
  font-weight: bolder;
  font-size: 130%;
}

.createcollegeNamePopup input,
.createcollegeNamePopup select {
  border-color: rgb(156, 155, 155) !important;
}

.createcollegeNamePopup::-webkit-scrollbar {
  width: 0;
}

.createcollegeNamePopup::-webkit-scrollbar-track {
  width: 0;
}

.notificationCount {
  width: 30px;
  height: 30px;
  /* border: 1px solid red; */
  border-radius: 50%;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.notificationCount span {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(50%, -30%);
  border-radius: 50%;
  font-size: 80%;
}

.pendingDocAlert.popup.active {
  display: block;
}

.pendingDocAlert.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 5px;
  z-index: 9999;
  /* Set the z-index higher than any other element on the page */
}

.overlay.active {
  display: block;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  /* Set the z-index lower than the popup container */
}

.pendingDocAlert .pending-documents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border: 2px solid #f44336;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

/* Style for icon */
.pending-documents i {
  color: #f44336;
  font-size: 3rem;
  margin-bottom: 10px;
}

/* Style for title */
.pending-documents h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

/* Style for message */
.pending-documents p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* Style for button */
.pending-documents button {
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

/* Hover style for button */
.pending-documents button:hover {
  background-color: #dc3545;
}

.remarks-list {
  width: 100%;
}

.remarks-list li {
  padding: 10px;
}

.remarks-list li:nth-child(even) {
  background: rgba(128, 128, 128, 0.444);
}

.remarks-list li div:nth-child(1) {
  font-weight: bolder;
}

.remarks-list li div:nth-child(2) {
  color: grey;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 14px;
}

/* table scrollable */
/* .files-table tr th {
  min-width: 100px;
} */

.files-table tr th.remark {
  min-width: 200px;
}

.files-table tr td.td-hover:hover {
  background: #93858563;
  color: green;
  cursor: pointer;
}

.payBtn.active .first {
  display: none;
}

.payBtn.active .second {
  display: block;
}

.payBtn .first {
  display: block;
}

.payBtn .second {
  display: none;
}

.files .tabs {
  width: 100%;
  margin-bottom: 10px;
  border: 0;
  background: none;
  display: flex;
  flex-direction: row;
}

.files .tabs>h2 {
  padding: 10px;
  border: 1px solid grey;
  cursor: pointer;
}

.files .tabs>h2.active {
  background: #2a276b;
  color: white;
}

.max-two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-two-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 40px !important;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 400px;
  color: white;
  background-color: #192733;
  padding: 5px;
}

.hover-text {
  cursor: pointer;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

/* .tooltip-text.top {
  top: -100px;
  left: -50px;
} */

.navbar-profile-dropdown>div {
  z-index: 9999;
}

.navbar-profile-dropdown img {
  z-index: 999;
  position: relative;
}

.navbar-profile-dropdown .overlay {
  z-index: 9998;
}

.navbar-profile-dropdown .overlay.show {
  display: block;
}

/* .agent */
.studentAddForm .tab-list {
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
}

.studentAddForm .tab-list .tab {
  color: black;
  padding: 20px;
  background: rgb(198, 195, 195);
  border-radius: 5px;
  margin: 10px;
  font-weight: bolder;
  cursor: pointer;
}

.studentAddForm .tab-list .tab.active {
  background: #2a276b;
  color: white !important;
}

.studentAddForm .tab-content {
  display: none;
}

.studentAddForm .tab-content.active {
  display: block;
}

.agent .programs-list {
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;
  justify-content: space-between;
}

.agent {
  width: 98%;
  margin: 28px 7px 0px 8px;
}

.agent .programs-list .program {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 10px; */
  border-radius: 5px;
  background: whitesmoke;
  border: 1px solid grey;
}

.agent .programs-list .program .top {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #19632f;
  width: 100%;
  color: white;
  padding: 4px;
  border-radius: 5px 5px 0 0;
}

.agent .programs-list .program .top img {
  margin-right: 10px;
}

.agent .programs-list .program .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin: 5px 0px;
}

.image-container {
  width: 100px;
  height: 100px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.agent .programs-list .program .body span {
  font-weight: bolder;
  margin-bottom: 10px;
}

.agent .programs-list .program .td {
  width: 100%;
  padding: 5px;
}

.agent .programs-list .program .td>td:nth-child(1) {
  font-weight: bolder;
}

.agent .programs-list .program button {
  width: 80%;
  border-radius: 30px;
  padding: 5px;
  text-align: center;
  background: #19632f;
  color: white;
  margin-bottom: 20px;
  margin-top: 10px;
}

.action-icons-list {
  display: flex;
  /* justify-content: center; */
}

.action-icon {
  fill: #2a276b;
  width: 18px;
  margin-left: 5px;
  margin-right: 5px;
}

.md-action-show path {
  color: #19632f;
}

.md-action-hide path {
  color: #f00;
}

/* bigLoading */
.bigLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
}

.files_documents {
  max-height: 70vh !important;
}

.filter_model .modal_inner {
  width: 60%;
}

.columns-popup {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.columns-popup p {
  width: 33%;
  margin: 5px 0;
  display: flex;
  align-items: flex-start;
}

table.files-table td select {
  max-width: 250px;
}

/* CSS */
.button-33 {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-33:hover {
  box-shadow: rgba(44, 187, 99, .35) 0 -25px 18px -14px inset, rgba(44, 187, 99, .25) 0 1px 2px, rgba(44, 187, 99, .25) 0 2px 4px, rgba(44, 187, 99, .25) 0 4px 8px, rgba(44, 187, 99, .25) 0 8px 16px, rgba(44, 187, 99, .25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

.loadingEnrollBtn {
  cursor: pointer;
}

.loadingEnrollBtn span:nth-child(1) {
  display: none;
}

.loadingEnrollBtn span:nth-child(2) {
  display: block;
}


/* Avatar Edit Profile */
.profile-flex {
  display: flex;
  justify-items: stretch;
  align-items: stretch;
  height: 100%;
  margin-top: 30px;
  border: 1px solid #ced4da;
  top: 80px;
  box-shadow: 0px 0px 5px 0px #d8d6d6;
  padding: 20px;
  width: 42%;
  border-radius: 5px;
}

.avatar-edit-profile {
  position: relative;
}

.avatar-edit-profile .loading {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.135);
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Toastify__toast-container {
  z-index: 9999999 !important;
}

.avatar-edit-profile img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #2a276b;
}

.avatar-edit-profile .edit-field {
  position: absolute;
  top: 5px;
  right: 5px;
}

.avatar-edit-profile .edit-field input {
  display: none;
}

.avatar-edit-profile .edit-field label {
  cursor: pointer;
  font-size: 20px;
  background: #2a276b;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding-left: 3px;
  padding-bottom: 3px;
  transition: .5s;
}

.avatar-edit-profile .edit-field label:hover {
  background: grey;
  transform: scale(1.1);
}

.profile-main {
  width: 100%;
  min-height: 100%;
}

.profile-main .tabs {
  position: sticky;
  top: 0;
  background: white;
  /* z-index: 99999; */
}

.left-profile {
  /* min-height: 100vh; */
  width: 40% !important;
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.left-profile h2 {
  font-weight: bolder;
  margin-top: 20px;
  font-size: 22px;
  color: #1a7335;
}

.right-profile {
  width: 80%;
  /* min-height: 100vh; */
  padding: 20px;
  background: white;
}

.right-profile button.eye-btn {
  position: absolute;
  bottom: 6px;
  right: 10px;
  background: none;
}

.right-profile button.eye-btn svg {
  font-size: 22px;
  fill: #000;
}

.cs-place img {
  width: 230px;
  height: 100%;
  border: 2px solid #2a276b;
  border-radius: 50%;
  margin: 0 auto;
}

.cs-file-upload input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (min-width: 768px) {
  table.files-table td select {
    max-width: 300px;
  }

  tr#file_0 td:last-child select {
    width: 100%;
  }

  .right-profile .profile-form:nth-child(1) button {
    display: flex;
    margin-left: auto;
    min-width: 161px;
    justify-content: center;
    border-radius: 0.375rem;
  }
}

@media (min-width: 992px) {
  .agentDetailsAsideBar table .tabDetail:last-child tr {
    flex-wrap: nowrap;
  }

  table.files-table td select {
    max-width: 341px;
  }
}

@media (min-width: 1020px) {


  .left-profile {
    width: 25% !important;
  }
}

@media screen and (max-width:1024px) {
  .agent .programs-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width:991px) {
  .agent .programs-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 767px) {

  .agentDetailsAsideBar table tr>div,
  .agentDetailsAsideBar table .question_row {
    width: 100%;
    align-items: center;
  }

  .agentDetailsAsideBar table tr th,
  .agentDetailsAsideBar table tr td,
  .tabDetail table tbody tr.docRow td:first-child,
  .agentDetailsAsideBar table tr .question,
  .agentDetailsAsideBar table tr .answer {
    font-size: 14px;
    min-width: fit-content;
  }

  .agentDetailsAsideBar .table-responsive .user {
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
  }

  .agentDetailsAsideBar table {
    overflow-x: scroll;
  }

  .agentDetailsAsideBar .table-responsive .user svg {
    height: 25px;
    width: 25px;
  }

  .user-details p {
    word-break: break-word;
  }

  .agentDetailsAsideBar table tr>div {
    flex-direction: column !important;
    align-items: flex-start;
  }
}

@media screen and (max-width:575px) {
  .agent .programs-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .pagination .pages ul li:nth-child(1) a,
  .pagination .pages ul li:last-child a {
    margin: 0 3px;
  }
}