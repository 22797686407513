#file-track * {
    transition: .5s ease-in;
}

#file-track {
    height: auto;
    background: rgba(148, 145, 146, 0.101);
    padding: 10px;
    border-radius: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-items: stretch;
}

#file-track li {
    display: flex;
    list-style-type: none;
    align-items: center;
}

#file-track li .a {
    height: 100px;
    position: relative;
    border-radius: 2px;
    width: 100%;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#file-track li .a span:nth-child(1) {
    font-weight: bold;
    margin-bottom: 0 !important;
}

#file-track li .a span:nth-child(2) {
    font-weight: lighter;
    margin-bottom: 5px !important;
}

#file-track li .a i {
    color: black;
    margin-right: 5px;
    cursor: pointer;
}

#file-track li .a button {
    margin-right: 5px;
    padding: 5px 10px;
    border-radius: 5px;
    background: #5876d1;
    color: white;
}


#file-track li .a.active {
    color: rgb(6 80 52);
}

#file-track .progress-line {
    width: 5px;
    background: rgb(180 219 198);
    margin-top: 50px;
    height: 0;
}

/* Levels - 1 */
#file-track .progress-line.h-1 {
    height: 0;
}

#file-track .progress-line.h-2 {
    height: 100px;
}

#file-track .progress-line.h-3 {
    height: 200px;
}

#file-track .progress-line.h-4 {
    height: 300px;
}

#file-track .progress-line.h-5 {
    height: 400px;
}

/* #file-track .progress-line.h-6 {
    height: 500px;
}

#file-track .progress-line.h-7 {
    height: 600px;
}

#file-track .progress-line.h-8 {
    height: 700px;
} */

#file-track li .a.active::before {
    background: rgb(37, 193, 133);
}

#file-track li .a.active.current::before {
    box-shadow: 0 0 10px 4px white;
    animation: currentStatus 3s ease-in-out infinite;
}

#file-track li .a.active.current.stop::before {
    animation: none;
    box-shadow: none;
    border: 1px solid black;
}

#file-track li .a.active.current.stop.success::before {
    background: green;
    transform: translate(-75%, -50%) scale(1.5);
}

#file-track li .a.active.current.stop.danger::before {
    background: red;
    transform: translate(-75%, -50%) scale(1.5);
}


@keyframes currentStatus {
    0% {
        box-shadow: 0 0 10px 2px green;
        transform: translate(-75%, -50%);
        /* background: green; */
    }

    50% {
        box-shadow: 0 0 10px 2px rgb(200, 203, 90);
        transform: translate(-75%, -50%) scale(1.6) rotate(180deg);
        /* background: rgb(56, 56, 223); */
    }

    100% {
        transform: translate(-75%, -50%) scale(1);
        box-shadow: 0 0 10px 2px green;
    }
}


#file-track li .a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-75%, -50%);
    width: 10px;
    height: 10px;
    background: grey;
    border-radius: 50%;
}