.country-banr {
  background: url(../images/skyscraper.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upr-img h5 {
  border-bottom: 2px solid #149449;
  box-shadow: 0 10px 10px #dadada;
}

.People-view {
  padding: 15px;
}

.People-view img {
  border: 1px solid #ddd;
  width: 100%;
}

.ppl-title {
  box-shadow: 0 10px 10px #dadada;
  border-bottom: 2px solid #149449;
  padding: 0px 15px 15px;
}

.ppl-title h4 {
  font-size: 22px;
}

/* .detail-part img {
  border: 2px solid #149449;
  border-radius: 50%;
  width: 100%;
  max-width: 80px;
  height: 80px;
  margin-right: 10px;
} */

.detail-part a {
  margin: 10px 0px 0px;
  width: 100%;
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #059669;
  color: #fff;
  padding: 6px 14px;
  font-size: 12px;
  border-radius: 3px;
  margin-top: 10px;
}

.detail-part {
  display: grid;
  grid-template-columns: 100%;
  text-align: center;
  gap: 30px;
}

.inner-detail-part .custom-de-page {
  margin: 16px auto 0px;
}

.inner-detail-part {
  border: 1px solid #ccc;
  padding: 30px 10px;
  background: #fff;
  border-radius: 3px;
}

.cs-info img {
  margin: 0px auto;
  display: block;
  text-align: center;
  height: auto;
  min-height: 130px;
  object-fit: cover;
}

.cs-info img {
  height: 130px;
  object-fit: contain;
  width: 130px;
}

.country-banr:after {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.country-banr h1 {
  color: #fff;
  font-size: 55px;
  font-weight: 300;
  text-transform: uppercase;

  z-index: 9;
  position: relative;
}

.inner-detail-part:hover h6 {
  color: #149449;
  transition: all 0.3s;
}

.inner-detail-part:hover .custom-de-page {
  background-color: #149449;
  transition: all 0.3s;
}

@media (min-width: 768px) {
  .detail-part {
    gap: 20px;
  }
}

@media (min-width: 992px) {
  .detail-part {
    grid-template-columns: 23.5% 23.5% 23.5% 23.5% !important;
  }
}

@media (min-width: 1200px) {
  .detail-part {
    grid-template-columns: 18.5% 18.5% 18.5% 18.5% 18.5% !important;
  }
}

@media (max-width: 767px) {
  .country-main-content .detail-part .inner-detail-part .cs-info {
    height: auto;
    width: auto;
  }

  .cs-info img {
    min-height: 104px;
    width: 170px;
    height: auto;
  }
}