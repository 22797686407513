@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://p.typekit.net/p.css?s=1&k=wzi6ffj&ht=tk&f=15780.15782.37496.37497&a=57009965&app=typekit&e=css");

@font-face {
font-family:"muli-italic";
src:url("https://use.typekit.net/af/437da9/00000000000000007735a0e5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/437da9/00000000000000007735a0e5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/437da9/00000000000000007735a0e5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/7fe570/00000000000000007735a0ee/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/7fe570/00000000000000007735a0ee/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/7fe570/00000000000000007735a0ee/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"muli-italic-bold";
src:url("https://use.typekit.net/af/21b2f2/00000000000000007735a100/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/21b2f2/00000000000000007735a100/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/21b2f2/00000000000000007735a100/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/b0bf15/00000000000000007735a103/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/b0bf15/00000000000000007735a103/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/b0bf15/00000000000000007735a103/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

body {
  font-family: "muli" !important;
  overflow-x: hidden;
}

body div,.selected  body div div button, body p, body h1, body h2, body h3, body h4, body h5 {
  font-family: "muli" !important;
}

.menuscrollheader-part ul.header-menus ul.head-sub-menus li:last-child a {
  color: #000 !important;
}

.menuscrollheader-part ul.header-menus ul.head-sub-menus li a:hover,
body ul.head-sub-menus li a:hover {
  color: #059669 !important;
}

.container {
  max-width: 1300px;
}

.m-10 {
  margin: 10px;
}

.text-danger {
  color: red;
}

nav.custom_nav_bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.top-bar {
  border-color: rgba(255, 255, 255, 0.2);
}

.header-part {
  position: fixed;
  width: 100%;
  z-index: 999999;
}

a.logo {
  max-width: 250px;
  height: 55px;
  overflow: hidden;
}

ul.head-sub-menus li a img {
  width: 30px;
  margin-right: 8px;
}

span.numb-text {
  border-right: 1px solid #fff;
  padding-right: 10px;
}

ul.head-sub-menus {
  background: #fff;
  min-width: max-content;
  width: fit-content;
  top: 80%;
  display: none;
  z-index: 9;
}

img.logo-scroll {
  display: none;
}

input#startDate {
  font-size: 15px !important;
  height: 35px !important;
}

input#endDate {
  font-size: 15px !important;
  height: 35px !important;
}

.DateRangePickerInput {
  height: 38px !important;
}

svg.DateRangePickerInput_arrow_svg.DateRangePickerInput_arrow_svg_1 {
  height: 15px !important;
  width: 15px !important;
}

.menuscrollheader-part img.logo-norml {
  display: none;
}

.menuscrollheader-part img.logo-scroll {
  display: block;
}

.menuscrollheader-part {
  position: fixed;
  z-index: 99999;
  width: 100%;
  background: #fff;
  top: 0;
}

.menuscrollheader-part ul.header-menus li a {
  color: #000;
}

.menuscrollheader-part.not-home ul.header-menus li a {
  color: white !important;
}

.menuscrollheader-part ul.header-menus li:last-child a {
  color: #fff !important;
}

ul.head-sub-menus li {
  margin-bottom: 8px;
  padding: 4px 10px !important;
  border-bottom: 1px solid #e3e3e3;
}

body ul.head-sub-menus li a {
  color: #000 !important;
  width: fit-content;
}

body ul.head-sub-menus li:last-child:hover a {
  color: #000 !important;
}

ul.header-menus li:hover ul.head-sub-menus {
  display: block;
}

ul.head-sub-menus li:last-child {
  border: 0px;
}

ul.head-sub-menus li a {
  text-transform: capitalize;
}

.about-part h2 span {
  color: #059669;
}

.menuscrollheader-part .top-bar {
  display: none;
}

ul.header-menus li a {
  color: #fff !important;
  display: flex;
}

ul.header-menus li:last-child:hover a {
  color: #fff !important;
}

ul.header-menus li a.apply-link {
  color: #fff !important;
}

input#exampleSearch::placeholder {
  color: #495057 !important;
  font-size: 1rem;
}

input#exampleSearch {
  color: #495057;
  padding: 0.375rem 0.75rem;
  width: 300px !important;
}

ul.header-menus li:last-child:hover {
  border: 0px !important;
}

ul.header-menus li {
  padding: 10px 0px;
}

.top-bar a {
  color: #fff;
}

ul.social-list li a {
  background: #149449;
  width: 45px;
  height: 45px;
  display: inline-block;
  border-radius: 50px;
  line-height: 46px;
  text-align: center;
  font-size: 16px;
}

img.bm-rect {
  bottom: 0;
  right: 0;
}

.top_tabs {
  background-color: #fff;
  border: 2px solid #fff;
  padding: 3px 10px;
  margin: 0px 6px;
  border-radius: 4px;
  line-height: 20px;
  font-size: 13px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.top_tabs:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, #19632f, #358f57);
  opacity: 0;
  z-index: -1;
}

.top_tabs:hover:before {
  opacity: 1;
}

.top_tabs:hover {
  background-color: transparent;
  color: #fff;
}

.top_tabs.active {
  background-color: transparent;
  color: #fff;
}

.header_notification_modal {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
}

.header_notification_modal::-webkit-scrollbar {
  width: 12px;
  border-radius: 6px;
}

.header_notification_modal::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
  border-radius: 6px;
}

.header_notification_modal::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 6px;
}


.dash_bord_form :where(.css-dev-only-do-not-override-12jzuas).ant-picker {
  padding: 10px 11px 4px !important;
}

.dash_bord_form .apply_buttn {
  padding: 9px 12px !important;
  background: #1a7335;
  color: #fff;
  border-radius: 5px;
  text-wrap: nowrap;
  min-width: 93px;
}

.dash_bord_form {
  padding-block: 20px;
}

.DateRangePickerInput__withBorder {
  border-radius: 4px !important;
}

.DateRangePicker_picker {
  z-index: 99 !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #00a699 !important;
}

.assesment-date-calender div .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1,
.assesment-date-calender div .DateInput.DateInput_1:last-child {
  display: none;
}

.apply_box {
  padding: 10px 18px;
  border: 1px solid rgba(56, 65, 74, 0.15);
  border-radius: 5px;
  transition: all 0.4s;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.apply_box p {
  font-size: 14px;
  font-weight: 800;
  color: #646464;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.app_zero {
  color: rgb(26, 115, 53) !important;
  font-weight: 800;
  margin-bottom: 0px !important;
  font-size: 24px !important;
}

.all_applications {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dash_bord_form label {
  font-size: 16px;
  color: #484848;
}

.css-1dyz3mf,
.css-3w2yfm-ValueContainer {
  display: flex !important;
  flex-direction: inherit !important;
  flex-wrap: nowrap !important;
  width: 200px !important;
  overflow: hidden !important;
}

.custom_nav_bar span.breadcrumb {
  display: none;
}

.abt-upr:after {
  content: "";
  display: block;
  position: absolute;
  background: #059669;
  height: 100px;
  width: 28%;
  bottom: 0;
}

.img-part {
  height: 450px;
  z-index: 9;
}

.main-up-img {
  height: 450px;
  object-fit: cover;
}

.about-part button.slick-arrow {
  display: none !important;
}

.about-part ul.slick-dots {
  bottom: 20px;
  text-align: right;
  padding-right: 80px;
}

.about-part ul.slick-dots li {
  margin: 0 !important;
}

.about-part ul.slick-dots li button:before {
  font-size: 15px !important;
  color: #fff !important;
  opacity: 1 !important;
}

.about-part ul.slick-dots li.slick-active button:before {
  color: #059669 !important;
}

img.crl-img {
  top: 0;
  left: 0;
}

.discover-cont {
  justify-content: center !important;
  align-items: center !important;
}

.custom-img img {
  height: 470px !important;
  object-fit: cover;
}

.log-div {
  height: 100px;
}

.log-div img {
  height: 100px;
  object-fit: cover;
}

.react-tabs__tab:focus:after {
  display: none;
}

.footer-content h2 {
  border-color: #149449;
}

.form-part h1 {
  color: #008000;
  border-color: #008000;
}

.right-cont p i {
  color: #60b044;
}

.inner-form sup {
  color: #149449;
}

.acc-head {
  background: #149449;
}

.right-cont p br {
  display: none;
}

.footer-content a:hover {
  color: #149449;
}

.accordion.ui .title {
  margin-bottom: 0px;
}

.title h2 {
  color: #000;
  font-size: 15px;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 0px;
}

.filter-part {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.54);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-part .right-part {
  max-height: 650px;
  overflow-y: scroll;
}

/* width */
.filter-part .right-part::-webkit-scrollbar {
  width: 0px;
}

button.view-program {
  background: #19632f;
}

.filter-part .right-part {
  width: 90%;
  max-width: 1020px;
  height: fit-content !important;
}



/* .search-content .filter-part .right-part {
  height: 0;
  overflow: hidden;
  top: 0px;
  width: 90%;
  position: absolute !important;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  max-width: 1100px;
} */

.filter-part.right-show .right-part {
  height: 470px;
}

.filter-part .right-part .content label {
  color: #000;
}

.filter-part .right-part input {
  border: 1px solid rgb(209 213 219);
  padding: 10px;
  border-radius: 5px;
}

.filter-part .right-part select {
  border: 1px solid rgb(209 213 219);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
  background: #fff;
}

.filter-part .right-part .content p {
  color: #000;
}

.filter-part .right-part .content .d-flex div {
  margin-bottom: 15px;
}

.filter-part .right-part .content .d-flex div label {
  margin-right: 10px;
}

.home-hero {
  background-position: center;
  background-size: cover;
}

.home-hero .slick-slide img {
  width: 100px;
  margin: 0px auto;
}

.home-hero .slick-slide {
  text-align: center;
}

.home-hero .inner-content {
  background: #fff;
  max-width: 240px;
  margin: 0px auto;
  padding: 10px;
  border-radius: 10px;
}

.home-hero .inner-content p {
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 700;
  font-style: italic;
  margin: 5px 0px 0px;
}

.home-hero .slick-next {
  right: 0 !important;
}

.home-hero .slick-prev {
  left: 0 !important;
  z-index: 999;
}

.home-hero ul.slick-dots {
  display: none !important;
}

.img-part {
  height: 450px;
}

.courses-part .react-tabs__tab-list {
  border-bottom: 0;
  margin-bottom: 0;
}

.courses-part .react-tabs__tab-list li {
  border: 0;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px;
  margin: 0 0 0 15px;
}

.courses-part .react-tabs__tab-list li:first-child {
  margin: 0px;
}

/* .courses-part .react-tabs__tab--selected {
  background: #ff0000 !important;
  color: #fff;
} */

.courses-part .react-tabs__tab {
  background: #000;
  color: #fff;
}

.courses-part h3 span {
  color: #059669;
}

.abt-upr:after {
  display: none;
}

.img-part {
  height: auto;
}

body {
  font-family: "Nunito Sans", sans-serif;
}

ul.header-menus li:last-child:hover {
  border: 0px !important;
}

ul.header-menus li {
  padding: 10px 0px;
}

ul.social-list li a {
  background: #149449;
  width: 45px;
  height: 45px;
  display: inline-block;
  border-radius: 50px;
  line-height: 46px;
  text-align: center;
  font-size: 16px;
}

.footer-content h2 {
  border-color: #149449;
}

.form-part h1 {
  color: #008000;
  border-color: #008000;
}

.right-cont p i {
  color: #60b044;
}

.inner-form sup {
  color: red;
}

.acc-head {
  background: #ed1b24;
}

.right-cont p br {
  display: none;
}

.footer-content a:hover {
  color: #149449;
}

.form-part .accordion.ui .title {
  margin-bottom: 15px;
}

.left-part::-webkit-scrollbar {
  width: 2px;
}

.left-part::-webkit-scrollbar-button {
  background: #ccc;
}

.left-part::-webkit-scrollbar-track-piece {
  background: rgb(255, 255, 255);
}

.left-part::-webkit-scrollbar-thumb {
  background: #eee;
}

.home-hero h1 {
  line-height: 55px;
}

.home-hero h1 span {
  font-weight: 700;
}

.home-hero .slick-slide img {
  width: 100px;
  margin: 0px auto;
}

.home-hero .slick-slide {
  text-align: center;
}

.home-hero .inner-content {
  background: #fff;
  max-width: 198px;
  margin: 0px auto;
  padding: 20px;
  border-radius: 25px;
}

.home-hero .inner-content p {
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 700;
  font-style: normal;
  margin: 5px 0px 0px;
}

.home-hero .slick-next {
  right: 0 !important;
}

/* .home-hero .slick-prev {
  left: 0 !important;
  z-index: 999;
  display: none !important;
} */

.home-hero ul.slick-dots {
  display: none !important;
}

.about-part a {
  background: #059669;
  width: 100%;
  max-width: 145px;
}

.courses-part .react-tabs__tab-list {
  border-bottom: 0;
}

.courses-part .react-tabs__tab-list li {
  border: 0;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px;
  margin: 0 0 0 15px;
}

.courses-part .react-tabs__tab-list li:first-child {
  margin: 0px;
}

.courses-part react-tabs__tab react-tabs__tab--selected {
  background: #059669 !important;
  color: #fff;
}

.courses-part .react-tabs__tab {
  background: #000;
  color: #fff;
}

.courses-part {
  background: #f0f0f0;
}

.courses-part .box {
  width: 50%;
}

p.country-text {
  color: #000;
  font-weight: 700;
}

.info-text p {
  color: #000;
  font-weight: bolder !important;
}

.info-text p b {
  color: #000;
  font-weight: 500 !important;
}

.filter-part .left-part {
  width: 100%;
}

.filter-part .right-part .title {
  border-bottom: 1px solid #fff;
  padding: 5px 10px !important;
}

.filter-part .content {
  padding: 5px 30px !important;
}

button.close-btn {
  background: #000;
  color: #fff;
  border-radius: 50px;
  padding: 5px;
  position: absolute;
  right: -11px;
  top: -12px;
  z-index: 999999;
}

.ReactModal__Content.ReactModal__Content--after-open {
  overflow: visible !important;
  max-width: 600px !important;
  width: 100%;
  padding: 0 !important;
}

.filter-part .right-part .title:nth-child(13) {
  border: 0 !important;
}

.info-text svg {
  color: #000;
}

.inner-course input {
  width: 75px;
}

button.apply-btn {
  padding: 8px 30px;
}

button.load-more-btn {
  background: #2a276b;
  color: #fff;
  padding: 8px 30px;
  border-radius: 30px;
}

.info-ftr {
  margin: 20px 0px 0px;
}

.left-log {
  background: url(https://report.icmr.org.in/images/bg.jpg);
  min-height: 100vh;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ryt-log .form-login {
  max-width: 500px;
  margin: 0px auto;
}

.text-info {
  color: #1a7335;
}

.left-log .slick-slider.slick-initialized {
  max-width: 600px;
  margin: 0px auto;
}

.left-log .slick-slider.slick-initialized img {
  width: 100%;
}

.logo-side {
  top: 40px;
  left: 40px;
}

.left-log .slick-dots {
  position: static !important;
}

.left-log .slick-dots li button:before {
  opacity: 1 !important;
  color: #fff !important;
  font-size: 15px !important;
}

.left-log .slick-dots li.slick-active button:before {
  color: #1a7335 !important;
}

/* .left-log .slick-prev,
.slick-next {
  display: none !important;
} */

.loginTabs span {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
}

.ryt-log .form-login button {
  background: #1a7335 !important;
  font-size: 15px;
  letter-spacing: 2px;
  padding: 10px 30px;
}

.main-login-box {
  height: auto;
  min-height: 550px;
}

/* .main-login-box {
    height: auto;
    min-height: 500px;
} */

.programsList li {
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 20px;
}

/* .programsList li:last-child {
  border-bottom: none;
} */

.fewSeatsLeft {
  /* border: 1px solid red; */
  padding: 5px 10px;
  /* border-radius: 5px; */
  float: right;
  /* background: rgba(255, 0, 0, 0.104); */
  margin-top: -5px;
  font-weight: bold;
}

.part1-row {
  width: 54%;
  border-color: #000;
}

.part2-row {
  width: 65%;
  border-color: 1px solid;
  /* background: #059669; */
}

button.check-btn {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.part2-row button {
  line-height: 22px;
  font-size: 12px;
}

.btm-row:last-child .part2-row {
  background: #059669;
  border: 1px solid #059669;
}

.part2-row button:first-child:after {
  content: "";
  display: block;
  position: absolute;
  background: #059669;
  height: 100%;
  width: 0%;
  top: 0;
  right: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: 0.3s;
  z-index: -9;
}

.part2-row button:first-child {
  position: relative;
  z-index: 99;
  background-color: #fff;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.part2-row button:first-child:hover:after {
  transition: 0.3s;
  width: 100%;
}

.part2-row button:first-child:hover {
  color: #fff;
}

.begining-part button {
  background: #000;
}

.levels h4 span {
  color: #059669;
}

.pop-form {
  max-width: 500px;
  margin: 0px auto;
}

.pop-form {
  background: #fff;
  text-align: left;
  border: 1px solid #ced4da;
  position: sticky;
  top: 80px;
  box-shadow: 0px 0px 5px 0px #d8d6d6;
  border-radius: 0px;
}

.pop-form h4 {
  font-size: 25px;
  text-align: center;
}

.pop-form input {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.pop-form select {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 10px;
}

p.global-text input {
  width: auto;
  margin-right: 10px;
}

p.global-text {
  display: flex;
  align-items: center;
}

p.global-text a {
  margin: 0 10px;
}

.pop-form button {
  background: #ee2222;
  color: #fff;
  width: 100%;
  padding: 12px 0px;
  border-radius: 5px;
}

.begining-part {
  background-image: url(./users/website/images/parl.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.levels h4 {
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.partner .slick-slide img {
  margin: 0px auto;
  display: block;
}

.partner button.slick-arrow {
  display: none !important;
}

.adm-col {
  width: 49%;
}

.admission {
  background: #f5f5f5;
}

.admission h4:after {
  content: "";
  display: block;
  position: absolute;
  background: #ee2222;
  height: 2px;
  width: 100px;
  left: 0;
  right: 0;
  margin: 0px auto;
  bottom: 0;
}

.admission h4 {
  padding-bottom: 30px;
}

.multi-form {
  max-width: 800px;
  min-height: 350px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.multi-form > label,
.multi-form > div > label {
  background: #181739;
  color: white;
  border-radius: 5px 5px 0 0;
  height: 100px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.multi-form .examLabels label {
  font-size: 20px;
  margin: 10px 0;
  background-color: transparent;
  color: black;
  text-align: left;
}

.multi-form span {
  display: none;
}

.multi-form li:before {
  background: #059669;
  color: #fff;
  font-size: 12px;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.multi-form li {
  border: 0;
}

.multi-form label {
  font-weight: 700;
  font-size: 30px;
  width: 100%;
  text-align: center;
  float: none;
  margin: 0px auto 50px;
  display: block;
}

.multi-form button:first-child {
  background: #000;
  color: #fff;
  border-radius: 50px;

  transition: 0.3s;
}

.multi-form button:first-child:hover {
  transition: 0.3s;
  opacity: 0.8;
}

.multi-form button:last-child {
  background: #059669;
  color: #fff;
  border-radius: 50px;

  float: right;
  transition: 0.3s;
}

.multi-form button:last-child:hover {
  transition: 0.3s;
  opacity: 0.8;
}

.multi-form input {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
}

.multi-form select {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.multi-form button {
  font-size: 15px;
  padding: 8px 35px;
  margin: 25px 0px 20px;
}

.multi-form ol {
  margin-bottom: 70px;
  justify-content: center;
}

.static-header {
  background: #000;
}

.singleStep {
  display: none;
}

.singleStep.active {
  display: flex;
  flex-direction: column;
}

div#toggleEligibleBox_0 ul li {
  border-bottom: 1px solid #ccc;
}

div#toggleEligibleBox_0 ul > div:nth-last-child(1) li {
  border-bottom: 0;
}

button.list-emp {
  background: #1a7335;
}

.card-body.create-employe {
  max-width: 800px;
  margin: 0px auto;
}

.card-body.create-employe label {
  font-size: 14px;
  font-weight: 700;
}

.card-body.create-employe button {
  background: #1a7335;
  padding: 7px 25px;
  font-size: 15px;
}

.agent-table tr {
  border-bottom: 0px solid #fff;
}

.agent-table th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.agent-table td {
  border: 0px;
}

.agent-table.student-table td {
  min-width: unset;
}

.overflow-auto.card.m-4.col-12.px-0.pt-0.pb-0.agent-table.border.student-table.table-overflow table tr td:not(:nth-child(1)) {
  min-width: 170px;
}

.agent-table table td img {
  border-radius: 0px;
}

table tr:last-child {
  border: 0 !important;
}

.new-left {
  min-height: 100vh;
  height: auto;
}

.css-1nmdiq5-menu {
  z-index: 10 !important;
}

table thead tr {
  position: relative;
  z-index: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.w-full .w-full.mb-5 .track {
  position: absolute;
  height: 6px;
  background-color: #ddd;
  top: 5.5px;
}

.w-full .w-full.mb-5 .thumb {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #333;
  background-color: #fff;
  cursor: grab;
  border-radius: 50%;
}

.w-full .w-full.mb-5 .thumb:hover {
  background-color: #ccc;
}

.w-full .w-full.mb-5 .selected {
  position: absolute;
  height: 6px;
  background-color: #007bff;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

table th:nth-child(1),
table tbody tr td:nth-child(1) {
  border-left: 1px solid #ced4da !important;
}

table.programTable th:nth-child(1),
table.programTabl tbody tr td:nth-child(1) {
  border-left: none !important;
}

table th:last-child,
table tbody tr td:last-child {
  border-right: 1px solid #ced4da !important;
}

table.programTable th:last-child,
table.programTabl tbody tr td:last-child {
  border-right: none !important;
}

table tbody tr:nth-child(odd) td {
  background: #fff;
}

table tbody tr:nth-child(even) td {
  background-color: #dfe7ef;
}

.files-table.table-hover tbody tr:hover td {
  background: rgb(219 234 254) !important;
}

.table-border {
  border: 1px solid #ced4da;
  padding: 0 !important;
}

table thead tr th {
  font-size: 16px;
  font-weight: 700;
}

table tbody tr td{
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 12px 0.5rem !important;
}

table tbody tr td p {
  font-size: 16px !important;
  font-weight: 500 !important;
}

table tbody tr td:nth-child(1) p div div {
  min-width: 20px;
}

table tbody tr:last-child td {
  border-bottom: 1px solid #ced4da !important;
}

.overflow-auto.card.my-3.col-12.px-0.pt-0.pb-0.agent-table.agent-commission-table.border {
  border: none;
}

.new-main {
  position: relative;
}

.video-container {
  position: absolute;
  top: 0;
  z-index: -1;
}

.new-right h2 b {
  color: #1c3479;
}

.new-right {
  height: auto;
  min-height: 100vh;
}

/* .part-cont:before {
  content: "";
  display: block;
  position: absolute;
  background: #bfbfbf;
  height: 1px;
  width: 100%;
  top: 8px;
} */

.input-content input::placeholder {
  color: #000;
  font-weight: 700;
  font-size: 15px;
}

.cs_check {
  font-size: 14px !important;
}

.cs_check_f {
  font-size: 14px !important;
}

.custom-field.one input.dirty,
.custom-field.one input:not(:placeholder-shown),
.custom-field.one input:focus {
  border-color: #000 !important;
  transition-delay: 0.1s;
}

span.placeholder {
  font-size: 16px;
  color: #000;
}

.forgot-link {
  color: #000;
}

button.log-btn {
  background: #1c3479;
  font-size: 15px;
}

.inner-form-data {
  max-width: 600px;
}

.login-part-modal {
  display: block !important;
}

.login-part-modal input {
  width: 30px;
  border: 1px solid #ababab;
}

.login-part-modal svg {
  top: -22px !important;
  right: -10px !important;
}

.adcol-btn {
  background: #059669 !important;

  font-size: 15px;
}

.filter-group.wid-label {
  margin: 25px 0px 0px;
}

.enter-data input[type="checkbox"] {
  position: relative;
  top: 2px;
}

.singleStep {
  display: none;
}

.singleStep.active {
  display: flex;
  flex-direction: column;
}

/****view-details*******/

.view-banr {
  background: url(../src/users/website/images/cl_banr.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.inn-main {
  width: 25%;
}

.inner-main {
  /* margin-top: -84px; */
  background: #fff;
  border-top: 4px solid #149449;
}

ul.view-tab-content li a.active {
  background: #149449;
}

ul.view-tab-content li a.active:after {
  background: #149449;
}

.viewform p a {
  color: #129449;
}

.viewtab-cont {
  margin-bottom: 50px;
}

input.register {
  background: #149449;
  color: #fff;
  font-size: 15px;
}

.css-12a83d4-MultiValueRemove,
.css-v7duua {
  display: none !important;
}

/******v-details*****/

/* 2 may 2023 */
.home-hero .inner-content img {
  width: 100%;
  max-width: 100px;
  min-height: 100% !important;
  height: 60px;
}

.text-green {
  color: #149449;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  font-size: 15px !important;
  margin-top: 0px !important;
}

ul.header-menus li a {
  padding: 0px 0px 2px;
  border-bottom: 2px solid transparent !important;
}

ul.header-menus li button a {
  padding-bottom: 0px;
}

ul.header-menus li .active,
ul.header-menus li a:hover {
  border-bottom: 2px solid #149449 !important;
}

ul.header-menus li.relative .active,
ul.header-menus li:last-child .active,
ul.header-menus li.relative a:hover,
ul.header-menus li:last-child a:hover {
  border-color: transparent !important;
}

/* ul.header-menus li:last-child a:hover {
  border-bottom: 0px !important;
} */

.top-bar a i {
  font-size: 15px;
}

.about-part h2 {
  font-size: 60px;
}

h4.apply_for {
  font-weight: 500 !important;
}

.contact-banr h1 {
  font-size: 62px;
  font-weight: 100;
  line-height: 3rem;
}

.searchbar-top .dc-search input {
  border: 1px solid #ced4da;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-radius: 10px 0px 0px 10px;
}

.searchbar-top .dc-search input:focus {
  border-color: #80bdff;
}

.right-form h4 {
  font-size: 20px;
  font-weight: 600;
  background: #333333;
}

/* 2 may 2023 */

/* 3 may 2023 */
.home-hero {
  padding-bottom: 60px;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.about-part p {
  letter-spacing: 0px;
  font-size: 15px;
  color: #333;
  font-weight: 600;
  text-align: center;
}

.courses-part ul.react-tabs__tab-list li:hover {
  background: #059669 !important;
  transition: 0.3s ease-in-out;
}

.courses-part .box {
  border-top: 3px solid #000000;
  box-shadow: 0 0 10px 0px #e6e6e6;
  transition: all 0.3s ease-out;
  background: #fff;
  padding: 15px;
}

.courses-part .box:hover {
  transform: scale(1.01);
  border-top: 3px solid #059669;
}

.log-div {
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin-right: 6px;
}

.log-div img {
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  width: 80px;
}

.course-title h3 {
  font-size: 20px !important;
  margin-top: 0;
  font-weight: 600 !important;
}

.about-part {
  padding-bottom: 0px !important;
}

.abt-upr:before {
  content: "";
  background: #059669;
  position: absolute;
  height: 120px;
  width: 50%;
  bottom: 0;
}

.inner-levels h5 {
  color: #333;
  font-size: 25px !important;
  font-weight: 600;
}

.partner .slick-slide img {
  margin: 0px auto;
  display: block;
  width: 100%;
  max-width: 150px;
  height: 100px;
  object-fit: contain;
}

.lower-content {
  background: #333;
}

ul.social-list li a:hover {
  background: #333;
  border: 1px solid #fff;
}

.home-hero ul li {
  font-size: 14px;
  font-weight: bold;
}

.right-form label {
  font-size: 14px;
  color: #6d6d6d;
}

.new-left {
  display: flex;
  flex-direction: column;
}

.new-left .cs-sign {
  width: 100%;
  max-width: 700px;
  padding: 30px;
}

.cs-imgg {
  border: 2px solid #b8b8b8;
  text-align: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
}

.cs-imgg img.un-logo {
  width: 90px;
}

.discover {
  background: #f6f6f6;
}

.top-bar input::-webkit-input-placeholder {
  /* Edge */
  text-align: center;
}

.top-bar input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-align: center;
}

.top-bar input::placeholder {
  text-align: center;
}

.footer-content ul li img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

/* 4 may 2023 */

/* 5 may 2023 */
.info-grp {
  margin-top: -85px;
}

ul.view-tab-content a:before {
  text-align: center;
  position: absolute;
  top: 4px;
  right: -17px;
  content: "";
  z-index: 1;
  border-left: 38px solid transparent;
  border-top: 38px solid #181739;
  content: "";
  height: 0;
  position: absolute;
  width: 0;
  transform: rotate(46deg);
}

ul.view-tab-content a.active::before {
  border-top-color: #149449;
}

.view-tab-content li a {
  background-color: #181739;
}

.im-div {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-image-container {
  width: 40px;
  height: 40px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.files-table.enrolled-list-table .small-image-container  {
  width: fit-content;
  height: fit-content;
  box-shadow: none;
}

.small-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.files-table.enrolled-list-table .small-image-container img {
  width: 40px;
  height: 40px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.college-list .im-div {
  padding: 10px;
  height: 65px;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.college-list .im-div img {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.custom_add_button {
  padding: 5px 10px !important;
  margin-top: 5px !important;
  border: 1px solid #059669 !important;
}

.custom_add_button.input-button {
  margin-top: 0 !important;
  min-height: 38px;
  border-radius: 4px;
}

.commission .custom_add_button {
  margin-top: 0 !important;
  min-width: 60px;
}

.drop-flex .block.w-full.flex-1,
.drop-flex .css-13cymwt-control {
  min-width: 191px;
  max-width: 191px !important;
}


.css-13cymwt-control, select {
  border-color: rgb(209 213 219)!important;
}

.css-13cymwt-control input {
  min-height: unset !important;
}

.css-1jqq78o-placeholder, select {
  color: #000 !important;
}

.sidebar_style .admin-side::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

/* 5 may 2023 */

/* 8 may 2023 */
.cs-country svg {
  margin-bottom: -4px !important;
  width: 20px;
  height: 20px;
}

.cs-view-img {
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  height: 320px;
}

.custom-dis-page {
  width: 30%;
}

.custom-dis-page .elig-btn,
.custon-el-btn {
  width: 100%;
  border-radius: 50px;
  padding: 10px 0px;
  /* font-size: 13px; */
  font-weight: 600;
  /* text-transform: uppercase; */
}

.custon-el-btn {
  border: 1px solid #181739 !important;
}

.custom-main-text {
  color: #059669;
}

.custom-text-con {
  font-size: 12px !important;
  line-height: 20px !important;
}

.cs-view-data {
  width: 65%;
}

.cs-search {
  box-shadow: 0px 0px 5px 0px #d8d6d6;
  border: 1px solid #ced4da;
  border-radius: 10px;
}

.createcollegeNamePopup label {
  background: transparent;
  color: #000;
}

.createcollegeNamePopup button {
  /* background: transparent; */
  color: #fff;
}

.createcollegeNamePopup {
  overflow: inherit !important;
}

.add_college_popup svg.close {
  position: absolute;
  top: -21px;
  right: -40px;
  overflow: visible;
}

.add_college_popup .card-body.p-2 {
  padding-top: 25px;
}

.add_college_popup .cs-btn button:hover {
  background: #059669 !important;
  color: #fff !important;
  transition: 0.3s ease-in-out;
}

.add_college_popup .cs-btn button {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: 1px solid #059669;
  border-radius: 5px;
  background: transparent !important;
  color: #059669 !important;
  font-size: 16px !important;
  transition: 0.3s ease-in-out;
  margin-top: 15px;
}

.add_college_popup button {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 5px;
  background: #059669;
  color: #fff !important;
  font-size: 13px !important;
  transition: 0.3s ease-in-out;
}

.add_college_popup.social svg.close {
  position: absolute;
  top: -29px;
  right: -29px;
  overflow: visible;
}

.add_college_popup.new_pop svg.close {
  position: absolute;
  top: -10px !important;
  right: -10px !important;
  overflow: visible;
}

.add_college_popup .card-body.p-2 {
  box-shadow: none;
}

.add_college_popup button:hover {
  background: none !important;
  border: 1px solid #059669;
  color: #059669 !important;
  transition: 0.3s ease-in-out;
}

.add_college_popup .span {
  font-size: 25px;
  text-transform: capitalize;
}

.createcollegeNamePopup.add_college_popup.social {
  overflow: visible !important;
}

.modaal {
  width: 80% !important;
  max-width: 460px !important;
  height: 270px !important;
  background: #fff !important;
  border-radius: 10px !important;
  padding: 20px !important;
}

.modaal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.modaal h1 {
  font-size: 24px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.cs-btn {
  display: flex;
  justify-content: flex-end;
}

.cs-btn button {
  color: #fff;
}

/* 8 may 2023 */

/* 9 may 2023 */
.show-btn:hover {
  background: rgb(5 150 105) !important;
}

/* 9 may 2023 */

/* 24 may 2023 */
.cs-view-img img {
  width: 100%;
  border-radius: 5px;
}

.admission-part .MuiPaper-root.MuiPaper-elevation {
  background: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: none;
}

.admission-part .MuiAccordionSummary-root {
  height: auto !important;
  min-height: auto;
}

.MuiAccordionSummary-root.Mui-expanded.MuiAccordionSummary-gutters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.admission-part .MuiAccordionSummary-root p {
  font-size: 20px;
  color: #333;
  font-weight: normal;
}

.admission-part .MuiAccordionSummary-root svg {
  color: #333;
  font-size: 35px;
}

.admission-part .MuiAccordionSummary-content {
  order: 2;
}

.admission-part .css-yw020d-MuiAccordionSummary-expandIconWrapper {
  -webkit-transform: rotate(277deg);
  -moz-transform: rotate(277deg);
  -ms-transform: rotate(277deg);
  transform: rotate(277deg);
}

.admission-part h2 strong {
  color: #059669;
}

.admission-part .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  opacity: 0;
}

.discover-cont {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

/* 24 may 2023 */

/* 25 may 2023 */
.custom-partc {
  height: 410px;
}

.custom-popcs {
  overflow: auto;
  height: 100vh;
}

.search_multiples::-webkit-scrollbar {
  height: 6px;
}

.search_multiples::-webkit-scrollbar-track-piece {
  background: #fff;
}

.search_multiples::-webkit-scrollbar-thumb {
  background: rgb(68 151 86);
}

/* 25 may 2023 */

/* 6 june 2023 */
.tab-apply-btn {
  float: right;
  margin: 20px 0px;
}

.cs-view-data {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 6 june 2023 */

.button-part button {
  border: 1px solid #059669;
  border-radius: 5px;
  color: #059669;
}

.button-part button:hover {
  background-color: #059669;
  color: #fff;
}

.profile-main button {
  background: #1a7335;
  color: #fff;
}

.profile-form {
  max-width: 415px !important;
  width: 100%;
}

.abouthero {
  background: url(../src/users/website/images/about.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.abouthero:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.dc-search input {
  width: 100%;
  outline: none;
}

img.count-flag {
  width: 30px;
}

.pt-main {
  width: 25%;
}

.elig-btn {
  background: #059669;
  margin: 20px auto 0;
  display: block;
  border: 1px solid #059669;
  color: #fff;
}

.vw-colg:hover {
  background: #059669;
  color: #fff;
}

.custon-el-btn:hover {
  background: #fff !important;
  color: #000;
}

.back-cont {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: scale(0);
  transition: 0.2s;
}

.pt-main:hover .back-cont {
  transform: scale(1);
  transition: 0.2s;
}

input.register {
  background: #059669;
}

.discover-cont p a {
  color: #059669;
}

.pt-main {
  width: 100%;
  margin-bottom: 10px;
}

.right-form {
  position: sticky;
  top: 10px;
}

.coverleft {
  position: sticky;
  top: 10px;
}

.aboutservice {
  background: #059669;
  color: #fff;
}

.how-work img {
  border: 2px dotted #059669;
  padding: 15px;
  width: 220px;
}

.contact-banr {
  background: url(../src/users/website/images/cbanner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-banr:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.bottm-headcont {
  background: #059669;
  width: 95%;
  margin: 0px auto;
  position: absolute;
  border: 0;
  bottom: 23px;
  left: 0;
  right: 0;
}

/* new */
.field_eye_view {
  position: relative;
}

.icon.icon-eye {
  position: absolute;
  right: 5px;
  top: -33px;
  cursor: pointer;
}

button#google_login_a {
  border: 1px solid #c1c1c1;
  border-radius: 6px;
}

.login_method_btns {
  width: 100%;
  margin: 25px 0px;
}

.login_method_btns button {
  background: #000;
  font-size: 1.15rem;
  padding: 10px;
  color: #fff;
  width: 50%;
}

.custom_change_tab button.click1 {
  background: #059669 !important;
  color: #fff !important;
}

.custom_change_tab button {
  background: transparent !important;
  font-size: 16px;
  padding: 10px;
  color: #059669 !important;
  width: 50%;
  border: 1px solid #059669;
}

#bt {
  width: 100% !important;
  background: #1c3479 !important;
  padding: 15px 0px !important;
}

.modal_cover {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.modal_inner {
  background-color: #fff;
  border-radius: 6px;
  left: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  width: 33%;
  transform: translate(-50%, -50%);
  padding: 20px;
}

.header_modal {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header_modal h1 {
  font-size: 25px;
}

.header_modal svg.close-custom {
  cursor: pointer;
  position: absolute;
  top: -16px;
  font-size: 32px;
  right: -20px;
  background: #ffffff;
  color: #000;
  padding: 8px;
  border-radius: 30px;
  box-shadow: 0px 4px 7px -3px #000;
}

.header_modal svg.close {
  cursor: pointer;
  position: absolute;
  top: -30px;
  font-size: 32px;
  right: -30px;
  background: #ffffff;
  color: #000;
  padding: 8px;
  border-radius: 30px;
  box-shadow: 0px 4px 7px -3px #000;
}

/* css for custom input type */
.custom-field {
  position: relative;
  display: inline-block;
  --field-padding: 12px;
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  outline: none;
  font-size: 14px;
}

.custom-field input.dirty + .placeholder,
.custom-field input:focus + .placeholder,
.custom-field input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #222;
}

/* ONE */
.custom-field.one input {
  background: none;
  border: 1px solid #919191;
  transition: border-color 0.3s ease;
}

.custom-field.one input + .placeholder {
  left: 8px;
  padding: 0 5px;
}

.custom-field.one input.dirty,
.custom-field.one input:not(:placeholder-shown),
.custom-field.one input:focus {
  border-color: #222;
  transition-delay: 0.1s;
}

.custom-field.one input.dirty + .placeholder,
.custom-field.one input:not(:placeholder-shown) + .placeholder,
.custom-field.one input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #222;
  background: #fff;
  width: auto;
}

.inner-form-part {
  max-width: 800px;
  margin: 0px auto;
  display: block;
  text-align: center;
}

.fees-part li:before {
  content: "";
  display: block;
  position: absolute;
  background: #059669;
  height: 10px;
  width: 10px;
  border-radius: 50px;
  left: 0;
  top: 6px;
}

.main_flex_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 0px;
  flex-direction: row-reverse;
  margin-top: 10px;
}

.fees-part li {
  padding: 0 0 10px 20px;
}

.partner-main {
  /* height: auto;
  width: auto;
  min-height: 120px;
  max-width: 150px; */
  padding: 10px;
  /* border: 1px solid #c5c5c5; */
  margin: 0px auto;
}

.partner-main img {
  height: 100px;
  object-fit: cover;
}

.no-spinner {
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.no-spinner::-moz-inner-spin-button {
  -moz-appearance: none;
  appearance: none;
}

.no-spinner::-moz-outer-spin-button {
  -moz-appearance: none;
  appearance: none;
}

.no-spinner {
  border: 1px solid #ccc;
  padding: 5px;
}

.cover-img img {
  width: 45px;
}

.cover-img {
  line-height: 30px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
}

.main-contry-upr {
  background: #f6f6f6;
}

.results {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 500px;
}

.results p {
  margin-right: 10px;
}

.results img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.pagination button[disabled] {
  background-color: #333;
  color: #fff;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.pagination button {
  padding: 8px 12px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  cursor: pointer;
}

.pagination button[disabled] {
  background-color: #333;
  color: #fff;
}

.pagination button:focus {
  outline: none;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
}

.results span {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #2a2a2a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  cursor: pointer;
}

.close_eligible_box {
  display: flex;
  justify-content: end;
}

.close_eligible_box .close {
  font-size: 18px;
  margin-top: -12px;
  cursor: pointer;
}

.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 300px;
  /* Adjust the width as needed */
}

.edit-modal-content h3 {
  margin-top: 0;
}

.edit-modal-content input {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.edit-modal-content button {
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 3px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.edit-modal-content button:hover {
  background-color: #0056b3;
}

.custom-tr {
  border-bottom: 10px solid #fff;
  height: 50px;
}

.errorRow {
  background-color: #ff0000;
  /* Customize this color as needed */
}

.DateRangePickerInput {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none !important;
}

.setting_icon {
  /* flex-direction: row-reverse; */
  width: 100%;
  justify-content: space-between;
}

/* 19/9/23 */
.new-right {
  background-image: url("./images/secbg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.inner-form-data {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  background: #fffffffa !important;
  /* border: 0px solid #059669; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgb(0 0 0 / 5%) 0px 18px 36px -18px inset; */
}

.inner-form-data.register {
  background: #fff !important;
}

.register-form input {
  max-width: 260px;
}

.inner-form-data.register label {
  font-size: 16px;
}

.inner-form-data button.log-btn {
  background: #059669 !important;
  transition: 0.3s ease-in-out;
  border: 1px solid #059669;
  font-size: 1rem;
  line-height: 1 !important;
}

.inner-form-data button.log-btn:hover {
  background: #fff !important;
  transition: 0.3s ease-in-out;
  color: #059669;
  border: 1px solid #059669;
}

.admin_log {
  position: absolute;
  top: -54px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.add_college_popup.new_ccs .login-part-modal svg {
  top: -38px !important;
  right: -29px !important;
}

.add_college_popup.new_ccs .modal_inner {
  width: 25%;
}

.sign_up button.log-btn {
  transition: 0.3s ease-in-out;
  border: 1px solid #059669;
  background: #059669 !important;
  color: #fff !important;
  width: fit-content !important;
  margin-top: 0 !important;
}

.sign_up button.log-btn:hover {
  transition: 0.3s ease-in-out;
  border: 1px solid #059669;
  background: #fff !important;
  color: #059669 !important;
}

.vissa {
  justify-content: center;
}

i.cs_search.fas.fa-search {
  padding: 10px 15px;
  background: #059669;
  color: #fff;
  margin-right: -23px;
}

input#exampleSearch {
  color: #495057;
  padding: 0.5rem 0.75rem;
  width: 300px !important;
  border: 2px solid #059669 !important;
  line-height: 16px;
}

.examLabels {
  flex-wrap: wrap;
}

.modal_inner.select-col-popup.add_open_popup {
  width: 100% !important;
  position: fixed;
  top: 50%;
}

.modal_cover.add_pop_bg {
  background: transparent;
}

.login-signup .new-right .inner-form-data .admin_logo a {
  width: fit-content;
  margin: auto;
}

/* 19/9/23 */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* 28 Nov 2023 */
.custom-text-right {
  text-align: right;
  word-break: break-all;
}

.custom-heading-text {
  border-radius: 0.5rem 0.5rem 0px 0px;
}

.custom-dashboard-section table th,
.custom-dashboard-section table td {
  padding: 10px;
}

.custom-dashboard-section table,
.custom-dashboard-section th,
.custom-dashboard-section td {
  border-collapse: collapse !important;
  border-width: 0px 0px 0.5px;
}

.custom-dashboard-section table tr td:first-child {
  font-weight: bold;
}

.custom-dashboard-section table th {
  color: #666;
}

.apply_box:first-child {
  border: 2px solid #1a7335;
}

.apply_box:nth-child(2) {
  border: 2px solid #1a7335;
}

.apply_box:nth-child(3) {
  border: 2px solid #1a7335;
}

.apply_box:nth-child(4) {
  border: 2px solid #1a7335;
}

.apply_box:nth-child(5) {
  border: 2px solid #1a7335;
}

.apply_box:nth-child(6) {
  border: 2px solid #1a7335;
}

@media (max-width: 1680px) {
  .profile-flex {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 1480px) {
  .profile-flex {
    width: 80%;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {

  button.view-program {
    font-size: 13px;
    margin-top: 10px;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .info-text p {
    font-size: 13px !important;
  }

  .inner-course input {
    width: 50px !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .cs-view-data .box-mob {
    display: flex;
    flex-direction: row;
  }

  .coverleft {
    width: 100%;
  }

  .profile-flex {
    width: 90%;
  }

  .left-log .slick-slider.slick-initialized {
    max-width: 100%;
  }

  .loginTabs {
    max-width: 381px;
  }

  .filter-part .right-part {
    position: static !important;
    margin-bottom: 20px !important;
  }

  .search-main {
    position: static !important;
    display: block !important;
  }

  img.un-logo {
    width: 100px;
    height: 100px;
    margin: 0px auto 20px;
  }

  .inner-course div {
    width: 20%;
    display: inline-block;
    margin: 0 8px 0px 0px;
  }

  .inner-course input {
    width: 100%;
  }

  .about-part p {
    margin-bottom: 20px;
  }

  .detail-part {
    grid-template-columns: 31% 31% 31% !important;
    justify-content: center;
  }

  .left-log .slick-slider.slick-initialized {
    max-width: 100%;
  }

  .loginTabs {
    max-width: 381px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .detail-part {
    grid-template-columns: 31% 31% 31% !important;
    justify-content: center;
  }

  .cs-view-data .box-mob {
    display: flex;
    flex-direction: row;
  }

  .cs-view-data {
    margin-top: 0px !important;
  }

  .coverleft {
    width: 100%;
  }

  .profile-flex {
    width: 100%;
  }

  .left-log {
    overflow-x: hidden;
  }

  .ryt-log .form-login {
    max-width: 100%;
  }

  .loginTabs {
    max-width: 100%;
  }

  .high-width {
    width: 100%;
  }

  .low-width {
    width: 100%;
  }

  .inn-main {
    width: 100%;
    margin-bottom: 10px;
  }

  .filter-part .right-part {
    position: static !important;
    margin-bottom: 20px;
  }

  .search-main {
    position: static !important;
    display: block !important;
  }

  img.un-logo {
    width: 100px;
    height: 100px;
    margin: 0px auto 20px;
  }

  .inner-course input {
    width: 100%;
  }

  .left-log {
    overflow-x: hidden;
  }

  .ryt-log .form-login {
    max-width: 100%;
  }

  .loginTabs {
    max-width: 100%;
  }

  .high-width {
    width: 100%;
  }

  .low-width {
    width: 100%;
  }

  .inn-main {
    width: 100%;
    margin-bottom: 10px;
  }
}

body {
  font-family: "Nunito Sans", sans-serif;
}

.container {
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.m-10 {
  margin: 10px;
}

.text-danger {
  color: red;
}

.top-bar {
  border-color: rgba(255, 255, 255, 0.2);
}

.header-part {
  position: fixed;
  width: 100%;
  z-index: 999999;
  transition: 0.5s;
}

ul.head-sub-menus li a img {
  width: 30px;
  margin-right: 8px;
}

span.numb-text {
  border-right: 1px solid #fff;
  padding-right: 10px;
}

ul.head-sub-menus {
  background: #fff;
  top: 100%;
  display: none;
  z-index: 9;
  border-radius: 5px;
  padding: 12px 0px;
}

img.logo-scroll {
  display: none;
}

.menuscrollheader-part img.logo-norml {
  display: none;
}

.menuscrollheader-part.not-home img.logo-norml {
  display: block;
}

.menuscrollheader-part img.logo-scroll {
  display: block;
}

.menuscrollheader-part.not-home img.logo-scroll {
  display: none;
}

.menuscrollheader-part {
  position: fixed;
  z-index: 99999;
  width: 100%;
  background: #fff;
  top: 0;
  transition: 0.5s;
}

.menuscrollheader-part ul.header-menus li a {
  color: #000 !important;
}

.menuscrollheader-part ul.header-menus li:last-child a {
  color: #fff !important;
}

ul.head-sub-menus li {
  padding: 0 !important;
  margin-bottom: 0px;
  padding: 4px 10px !important;
  border-bottom: 1px solid #efefef;
}

body ul.head-sub-menus li a {
  padding-bottom: 0;
  font-weight: 600;
  border-bottom: 2px solid transparent;
}

body ul.head-sub-menus li:last-child:hover a {
  color: #059669 !important;
}

body ul.head-sub-menus li:last-child a {
  border: none !important;
  padding-bottom: 0 !important;
}

ul.header-menus li:hover ul.head-sub-menus {
  display: block;
}

ul.head-sub-menus li:last-child {
  border: 0px;
}

ul.head-sub-menus li a {
  text-transform: capitalize;
  font-size: 1rem;
}

.about-part h2 span {
  color: #059669;
  margin-right: 15px;
  position: relative;
}

.menuscrollheader-part .top-bar {
  display: none;
}

ul.header-menus li a {
  color: #fff !important;
  display: flex;
}

ul.header-menus li:last-child:hover a {
  color: #fff !important;
}

ul.header-menus li a.apply-link {
  color: #fff !important;
}

input#exampleSearch::placeholder {
  color: #000;
}

ul.header-menus li:last-child:hover {
  border: 0px !important;
}

ul.header-menus li {
  padding: 10px 0px;
}

.top-bar a {
  color: #fff;
}

ul.social-list li a {
  background: #149449;
  width: 45px;
  height: 45px;
  display: inline-block;
  border-radius: 50px;
  line-height: 46px;
  text-align: center;
  font-size: 16px;
}

img.bm-rect {
  bottom: 0;
  right: 0;
}

.abt-upr:after {
  content: "";
  display: block;
  position: absolute;
  background: #059669;
  height: 100px;
  width: 28%;
  bottom: 0;
}

.img-part {
  height: 450px;
  z-index: 9;
}

.main-up-img {
  height: 450px;
  object-fit: cover;
}

.about-part button.slick-arrow {
  display: none !important;
}

.about-part ul.slick-dots {
  bottom: 20px;
  text-align: right;
  padding-right: 80px;
}

.about-part ul.slick-dots li {
  margin: 0 !important;
}

.about-part ul.slick-dots li button:before {
  font-size: 15px !important;
  color: #fff !important;
  opacity: 1 !important;
}

.about-part ul.slick-dots li.slick-active button:before {
  color: #059669 !important;
}

img.crl-img {
  top: 0;
  left: 0;
}

.courses-part ul.react-tabs__tab-list li {
  border-radius: 50px;
  padding: 8px 30px;
  font-weight: 400;
  font-size: 15px;
}

.react-tabs__tab:focus:after {
  display: none;
}

.footer-content h2 {
  border-color: #149449;
}

.form-part h1 {
  color: #008000;
  border-color: #008000;
}

.right-cont p i {
  color: #60b044;
}

.inner-form sup {
  color: #149449;
}

.acc-head {
  background: #149449;
}

.right-cont p br {
  display: none;
}

.footer-content a:hover {
  color: #149449;
}

.accordion.ui .title {
  margin-bottom: 0px;
}

.title h2 {
  color: #000;
  font-size: 15px;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 0px;
}

.css-1jqq78o-placeholder {
  color: #b5b5b5 !important;
}

.multistep .input-container input, .multistep .input-container select {
  min-height: 54px !important;
}

.filter-part .right-part .content label {
  color: #000;
}

.filter-part .right-part input {
  border: 1px solid rgb(209 213 219);
  padding: 10px;
  border-radius: 5px;
  min-height: 38px;
}

.filter-part .right-part select {
  border: 1px solid rgb(209 213 219);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
}

.filter-part .right-part .content p {
  color: #000;
}

.filter-part .right-part .content .d-flex div {
  margin-bottom: 15px;
}

.filter-part .right-part .content .d-flex div label {
  margin-right: 10px;
}

.home-hero {
  background: url(./users/website/images/banr.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}

.home-hero .slick-slide img {
  width: 100px;
  margin: 0px auto;
}

.home-hero .slick-slide {
  text-align: center;
}

.home-hero .inner-content {
  background: #fff;
  max-width: 240px;
  margin: 0px auto;
  padding: 10px;
  border-radius: 10px;
}

.home-hero .inner-content p {
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 700;
  font-style: italic;
  margin: 5px 0px 0px;
}

.home-hero .slick-next {
  right: 0 !important;
}

.home-hero .slick-prev {
  left: 0 !important;
  z-index: 999;
}

.home-hero ul.slick-dots {
  display: none !important;
}

.img-part {
  height: 450px;
}

.courses-part .react-tabs__tab-list {
  border-bottom: 0;
}

.courses-part .react-tabs__tab-list li {
  border: 0;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px;
  margin: 0 0 0 15px;
}

.courses-part .react-tabs__tab-list li:first-child {
  margin: 0px;
}

.courses-part .react-tabs__tab--selected {
  background: #ff0000 !important;
  color: #fff;
}

.courses-part .react-tabs__tab {
  background: #000;
  color: #fff;
}

.courses-part h3 span {
  color: #059669;
}

.abt-upr:after {
  display: none;
}

.img-part {
  height: auto;
}

.img-part .container {
  padding: 0px !important;
}

body {
  font-family: "Nunito Sans", sans-serif;
}

ul.header-menus li:last-child:hover {
  border: 0px !important;
}

ul.header-menus li {
  padding: 10px 0px;
}

.footer-content h2 {
  border-color: #149449;
  border-width: 0px 0px 2px;
}

.form-part h1 {
  color: #008000;
  border-color: #008000;
}

.right-cont p i {
  color: #60b044;
}

.inner-form sup {
  color: red;
}

.acc-head {
  background: #ed1b24;
}

.right-cont p br {
  display: none;
}

.footer-content a:hover {
  color: #149449;
}

.form-part .accordion.ui .title {
  margin-bottom: 15px;
}

.left-part::-webkit-scrollbar {
  width: 2px;
}

.left-part::-webkit-scrollbar-button {
  background: #ccc;
}

.left-part::-webkit-scrollbar-track-piece {
  background: rgb(131, 120, 120);
}

.left-part::-webkit-scrollbar-thumb {
  background: #eee;
}

.home-hero h1 {
  line-height: 70px;
  font-weight: 700;
  padding: 40px 0px;
}

.home-hero h1 span {
  font-weight: 700;
}

.home-hero .slick-slide img {
  width: 100px;
  margin: 0px auto;
  min-height: 100px;
  object-fit: cover;
}

.home-hero .slick-slide {
  text-align: center;
}

.home-hero .inner-content {
  background: #fff;
  max-width: 200px;
  margin: 0px auto;
  padding: 12px 0px;
  border-radius: 25px;
  height: auto;
  min-height: auto;
}

.home-hero .inner-content p {
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 700;
  font-style: normal;
  margin: 10px 0px 0px;
}

.home-hero .slick-next {
  right: 0 !important;
}

.home-hero .slick-prev {
  left: 0 !important;
  z-index: 999;
  /* display: none !important; */
}

.home-hero .slick-prev:before {
  content: "\f053" !important;
  font-family: "FontAwesome" !important;
  font-size: 50px !important;
}

.home-hero .slick-next:before {
  content: "\f054" !important;
  font-family: "FontAwesome" !important;
  font-size: 50px !important;
}

.home-hero .slick-prev {
  left: -40px !important;
}

.home-hero .slick-next {
  right: -40px !important;
}

.home-hero ul.slick-dots {
  display: none !important;
}

.about-part a {
  background: #059669;
  width: 100%;
  max-width: 145px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: "Nunito Sans";
  font-weight: 400;
}

.courses-part .react-tabs__tab-list {
  border-bottom: 0;
}

.courses-part .react-tabs__tab-list li {
  border: 0;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px;
  margin: 0 0 0 15px;
}

.courses-part .react-tabs__tab-list li:first-child {
  margin: 0px;
}

.courses-part .react-tabs__tab--selected {
  background: #059669 !important;
  color: #fff;
}

.courses-part .react-tabs__tab {
  background: #000;
  color: #fff;
}

.courses-part {
  background: #f9f9f9;
}

.courses-part .box {
  width: 50%;
}

p.country-text {
  color: #000;
  font-weight: 700;
}

.info-text p {
  color: #000;
  font-weight: bolder !important;
}

.info-text p b {
  color: #000;
  font-weight: 500 !important;
}

.filter-part .left-part {
  width: 100%;
}

.filter-part .right-part.right-show {
  height: 500px;
  display: flex;
  width: 85%;
  top: 145px;
  transition: height 0.8s ease-in-out;
}

.filter-part .right-part .title {
  border-bottom: 1px solid #fff;
  padding: 5px 10px !important;
}

.filter-part .content {
  padding: 5px 10px !important;
}

button.close-btn {
  background: #000;
  color: #fff;
  border-radius: 50px;
  padding: 5px;
  position: absolute;
  right: -11px;
  top: -12px;
  z-index: 999999;
}

.ReactModal__Content.ReactModal__Content--after-open {
  overflow: visible !important;
  max-width: 600px !important;
  width: 100%;
  padding: 0 !important;
}

.edu-content {
  width: 100%;
  max-width: 500px;
}

.info-data {
  display: block;
  height: 100vh;
  border-top-left-radius: 30px;
  background-color: #fff;
  left: 105%;
  position: fixed;
  top: 0%;
  width: 70%;
  z-index: 99999;
  transition: 1.2s ease-in-out;
  border-left: 1px solid #949494;
}

.open-data {
  left: 30%;
  animation: slide-in2 1.2s ease-in-out;
}

@keyframes slide-in {
  0% {
    left: 100%;
    /* Start position */
  }

  100% {
    left: 70%;
    /* End position */
  }
}

@keyframes slide-in2 {
  0% {
    left: 100%;
    /* Start position */
  }

  100% {
    left: 30%;
    /* End position */
  }
}

.open-data::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.open-data::-webkit-scrollbar {
  width: 6px;
  background-color: #059669;
}

.open-data::-webkit-scrollbar-thumb {
  background-color: #059669;
}

.filter-part .right-part .title:nth-child(13) {
  border: 0 !important;
}

.info-text p {
  font-size: 13px;
}

.info-text svg {
  color: #000;
}

.inner-course input {
  width: 100%;
}

button.apply-btn {
  padding: 8px 30px;
}

button.load-more-btn {
  background: #2a276b;
  color: #fff;
  padding: 8px 30px;
  border-radius: 30px;
}

.info-ftr {
  margin: 20px 0px 0px;
}

.left-log {
  background: url(https://report.icmr.org.in/images/bg.jpg);
  min-height: 100vh;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ryt-log .form-login {
  max-width: 500px;
  margin: 0px auto;
}

.text-info {
  color: #1a7335;
}

.left-log .slick-slider.slick-initialized {
  max-width: 600px;
  margin: 0px auto;
}

.left-log .slick-slider.slick-initialized img {
  width: 100%;
}

.logo-side {
  top: 40px;
  left: 40px;
}

.left-log .slick-dots {
  position: static !important;
}

.left-log .slick-dots li button:before {
  opacity: 1 !important;
  color: #fff !important;
  font-size: 15px !important;
}

.left-log .slick-dots li.slick-active button:before {
  color: #1a7335 !important;
}

.loginTabs span {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
}

.ryt-log .form-login button {
  background: #1a7335 !important;
  font-size: 15px;
  letter-spacing: 2px;
  padding: 10px 30px;
}

.main-login-box {
  height: auto;
  min-height: 550px;
}

/* .main-login-box {
    height: auto;
    min-height: 500px;
} */

.programsList li::after {
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 20px;
}

/* .programsList li:last-child {
  border-bottom: none;
} */

.fewSeatsLeft {
  /* border: 1px solid red; */
  padding: 5px 10px;
  /* border-radius: 5px; */
  float: right;
  /* background: rgba(255, 0, 0, 0.104); */
  margin-top: -5px;
  font-weight: bold;
}

.part1-row {
  width: 54%;
  border-color: #000;
}

.part2-row {
  width: 65%;
  border-color: 1px solid;
  /* background: #059669; */
}

button.check-btn {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.part2-row button {
  line-height: 22px;
  font-size: 12px;
}

.btm-row:last-child .part2-row {
  background: #000000;
  border: 1px solid #000000;
}

.part2-row button:first-child:after {
  content: "";
  display: block;
  position: absolute;
  background: #059669;
  height: 100%;
  width: 0%;
  top: 0;
  right: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: 0.3s;
  z-index: -9;
}

.part2-row button:first-child {
  position: relative;
  z-index: 99;
  background-color: #fff;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.part2-row button:first-child:hover:after {
  transition: 0.3s;
  width: 100%;
}

.part2-row button:first-child:hover {
  color: #fff;
}

.begining-part button {
  background: #000;
}

.levels h4 span {
  color: #059669;
  font-weight: 500;
}

.pop-form {
  max-width: 500px;
  margin: 0px auto;
}

.pop-form {
  max-width: 500px;
  margin: 0px auto;
  border: 1px solid #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 10%);
}

.pop-form h4 {
  font-size: 25px;
  text-align: center;
}

.pop-form input {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.pop-form select {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 10px;
}

p.global-text input {
  width: auto;
  margin-right: 10px;
}

p.global-text {
  display: flex;
  align-items: center;
}

p.global-text a {
  margin: 0 10px;
}

.pop-form button {
  background: #ee2222;
  color: #fff;
  width: 100%;
  padding: 12px 0px;
  border-radius: 5px;
}

.begining-part {
  background-image: url(./users/website/images/parl.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.begining-part:before {
  content: "";
  display: block;
  position: absolute;
  background: #059669d6;
  height: 100%;
  width: 100%;
  top: 0;
}

.levels h4 {
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-weight: 100;
}

.partner .slick-slide img {
  margin: 0px auto;
  display: block;
}

.partner button.slick-arrow {
  display: none !important;
}

.levels {
  background: #f5f5f5;
}

.adm-col {
  width: 49%;
}

.admission {
  background: #f5f5f5;
}

.admission h4:after {
  content: "";
  display: block;
  position: absolute;
  background: #ee2222;
  height: 2px;
  width: 100px;
  left: 0;
  right: 0;
  margin: 0px auto;
  bottom: 0;
}

.admission h4 {
  padding-bottom: 30px;
}

.multi-form {
  max-width: 800px;
  min-height: 350px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.multi-form > label,
.multi-form > div > label {
  background: #181739;
  color: white;
  border-radius: 5px 5px 0 0;
  height: 100px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.multi-form .examLabels label {
  font-size: 20px;
  margin: 10px 0;
  background-color: transparent;
  color: black;
  text-align: left;
}

.multi-form span {
  display: none;
}

.multi-form li:before {
  background: #059669;
  color: #fff;
  font-size: 12px;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.multi-form li {
  border: 0;
}

.multi-form label {
  font-weight: 700;
  font-size: 30px;
  width: 100%;
  text-align: center;
  float: none;
  margin: 0px auto 50px;
  display: block;
}

.multi-form button:first-child {
  background: #000;
  color: #fff;
  border-radius: 50px;

  transition: 0.3s;
}

.multi-form button:first-child:hover {
  transition: 0.3s;
  opacity: 0.8;
}

.multi-form button:last-child {
  background: #059669;
  color: #fff;
  border-radius: 50px;

  float: right;
  transition: 0.3s;
}

.multi-form button:last-child:hover {
  transition: 0.3s;
  opacity: 0.8;
}

.multi-form input {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
}

.multi-form select {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.multi-form button {
  font-size: 15px;
  padding: 8px 35px;
  margin: 25px 0px 20px;
}

.multi-form ol {
  margin-bottom: 70px;
  justify-content: center;
}

.static-header {
  background: #000;
}

.singleStep {
  display: none;
}

.singleStep.active {
  display: flex;
  flex-direction: column;
}

div#toggleEligibleBox_0 ul li {
  border-bottom: 1px solid #ccc;
}

div#toggleEligibleBox_0 ul > div:nth-last-child(1) li {
  border-bottom: 0;
}

button.list-emp {
  background: #1a7335;
}

.card-body.create-employe {
  max-width: 800px;
  margin: 0px auto;
}

.card-body.create-employe label {
  font-size: 14px;
  font-weight: 700;
}

.card-body.create-employe button {
  background: #1a7335;
  padding: 7px 25px;
  font-size: 15px;
}

.tab-content {
  display: none;
}

.tab-content:first-child {
  display: block;
}

.agent-table thead tr th {
  position: sticky;
  top: 0;
  background: #ced4da;
  color: #000;
  padding: 12px 0.5rem !important;
}

.agent-table tr {
  border-bottom: 0px solid #fff;
}

.agent-table.student-table tr {
  border-bottom: 0 !important;
}

.agent-table th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.agent-table td {
  border: 0px;
}

table tr:last-child {
  border: 0 !important;
}

.new-left {
  min-height: 100vh;
  height: auto;
}

.new-right h2 {
  font-size: 20px;
  line-height: normal;
  font-weight: bold;
}

.new-right h2 b {
  color: #1c3479;
}

.new-right {
  height: auto;
  min-height: 100vh;
}

.input-content input::placeholder {
  color: #000;
  font-weight: 700;
  font-size: 15px;
}

.forgot-link {
  color: #6358dc;
}

button.log-btn {
  background: #1c3479;
  font-size: 15px;
}

.inner-form-data {
  max-width: 600px;
}

.login-part-modal {
  display: block !important;
}

.login-part-modal input {
  width: 30px;
  border: 1px solid #ababab;
}

.login-part-modal p {
  width: 200px;
  background: #059669;
  color: #fff;
  padding: 12px 0px;
  border-radius: 10px;
  margin: 20px auto 0px;
  font-size: 18px;
  display: block;
  text-align: center;
  cursor: pointer;
}

.login-part-modal svg {
  top: -22px !important;
  right: -10px !important;
}

.adcol-btn {
  background: #059669 !important;

  font-size: 15px;
}

.filter-group.wid-label {
  margin: 25px 0px 0px;
}

.enter-data input[type="checkbox"] {
  position: relative;
  top: 2px;
}

.singleStep {
  display: none;
}

.singleStep.active {
  display: flex;
  flex-direction: column;
}

/****view-details*******/

.view-banr {
  background: url(../src/images/Durham-College.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.inn-main {
  width: 25%;
  border-radius: 2px;
  padding: 8px;
}

.inn-main:hover {
  background: #149449;
  color: #fff;
}

.inner-main {
  background: #fff;
  border-top: 4px solid #149449;
}

ul.view-tab-content a {
  text-align: center;
}

ul.view-tab-content li a.active {
  background: #149449;
}

ul.view-tab-content li a.active:after {
  background: #149449;
}

ul.view-tab-content a:hover {
  background: #149449;
}

ul.view-tab-content a:hover:before {
  border-top: 38px solid#149449;
}

.viewform p a {
  color: #129449;
}

.viewtab-cont {
  margin-bottom: 50px;
}

input.register {
  background: #149449;
  color: #fff;
  font-size: 15px;
}

/******v-details*****/

.button-part button {
  border: 1px solid #059669;
  border-radius: 5px;
  color: #059669;
}

.button-part button:hover {
  background-color: #059669;
  color: #fff;
}

.profile-main button {
  background: #1a7335;
  color: #fff;
}

.profile-form {
  max-width: 415px !important;
  width: 100%;
  border: 1px solid #ced4da;
  top: 80px;
  box-shadow: 0px 0px 5px 0px #d8d6d6;
  padding: 20px;
  border-radius: 5px;
}

.profile-main.admin-profile .profile-form {
  border: none;
  box-shadow: none;
  padding: 0;
}

.abouthero {
  background: url(../src/users/website/images/about.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.abouthero:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.dc-search input {
  width: 100%;
  outline: none;
}

.pt-main {
  width: 25%;
}

.back-cont {
  background: #333;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: scale(0);
  transition: 0.2s;
}

.pt-main:hover .back-cont {
  transform: scale(1);
  transition: 0.2s;
}

input.register {
  background: #059669;
}

.discover-cont p a {
  color: #059669;
}

.pt-main {
  width: 100%;
  margin-bottom: 10px;
}

.right-form {
  position: sticky;
  top: 10px;
  box-shadow: 0px 0px 5px 0px #d8d6d6;
  border: 1px solid #ced4da;
}

.right-form input,
.right-form select {
  border-radius: 0.25rem;
  color: #000;
}

.right-form .p-4 p:last-child input {
  color: #fff;
}

.coverleft {
  position: sticky;
  top: 10px;
}

.aboutservice {
  background: #059669;
  color: #fff;
}

.how-work img {
  border: 2px dotted #059669;
  padding: 15px;
  width: 220px;
}

.contact-banr {
  background: url(../src/users/website/images/cbanner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-banr:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.54);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.bottm-headcont {
  background: #059669;
  width: 90%;
  margin: 0px auto;
  position: absolute;
  border: 0;
  bottom: 50px;
  left: 0;
  right: 0;
}

.bottm-headcont h3 {
  font-weight: 300;
  font-size: 1.75rem;
}

/* new */
.field_eye_view {
  position: relative;
}

.icon.icon-eye {
  position: absolute;
  right: 13px;
  top: -33px;
  cursor: pointer;
}

button#google_login_a {
  border: 1px solid #c1c1c1;
  border-radius: 6px;
  font-size: 1rem;
  line-height: 1 !important;
}

button#google_login_a img {
  max-width: 20px !important;
}

.login_method_btns {
  width: 100%;
  margin: 15px 0px;
}

.login_method_btns button {
  background: #000;
  font-size: 1.15rem;
  padding: 10px;
  color: #fff;
  width: 50%;
}

button.click1 {
  background: #1c3479;
}

#bt {
  width: 100% !important;
  background: #1c3479 !important;
  padding: 15px 0px !important;
}

.modal_cover {
  background-color: #00000070;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.modal_inner {
  background-color: #fff;
  border-radius: 6px;
  left: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  padding: 20px;
  max-width: 500px;
}

.header_modal {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header_modal h1 {
  font-size: 25px;
}

.header_modal svg.close {
  position: absolute;
  top: -30px;
  right: -30px;
}

.close {
  cursor: pointer;
  font-size: 32px;
  background: #ffffff;
  color: #000;
  padding: 8px;
  border-radius: 30px;
  box-shadow: 0px 4px 7px -3px #000;
}

.rotate {
  animation: rotateLoader 1s linear infinite;
}

.reload-icon i {
  cursor: pointer;
  font-size: 25px;
  color: #1a7335;
  margin-top: 2px;
}

@keyframes rotateLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

svg.Bar_filter {
  color: #0000009c;
  background: transparent;
  border: none;
  border-radius: 5px;
  padding: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

#aside .subGroupMenuItems button:hover .subItemIconDot {
  background: #008000 !important;
}

#aside .subGroupMenuItems button:hover .sidebaritem {
  color: #008000 !important;
}

/* css for custom input type */
.custom-field {
  position: relative;
  display: inline-block;
  --field-padding: 12px;
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  outline: none;
  font-size: 14px;
}

.custom-field input.dirty + .placeholder,
.custom-field input:focus + .placeholder,
.custom-field input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #222;
}

/* ONE */
.custom-field.one input {
  background: none;
  border: 1px solid #919191;
  transition: border-color 0.3s ease;
}

.custom-field.one input + .placeholder {
  left: 8px;
  padding: 0 5px;
}

.custom-field.one input.dirty,
.custom-field.one input:not(:placeholder-shown),
.custom-field.one input:focus {
  border-color: #222;
  transition-delay: 0.1s;
}

.custom-field.one input.dirty + .placeholder,
.custom-field.one input:not(:placeholder-shown) + .placeholder,
.custom-field.one input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #222;
  background: #fff;
  width: auto;
}

.info-ftr button {
  background: #059669 !important;
}

.search-main {
  position: sticky;
  background: #fff;
  top: 0;
}

.filter-part .right-part .filter-tab-title.active {
  background: #1c3479;
  color: #fff;
}

.filter-part .right-part .filter-tab-title.active h2 {
  font-weight: 700;
  border: 0px;
  color: #fff;
}

img.closebar-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: none;
}

.high-width .border img {
  margin: 0px auto;
  padding: 10px;
}

.chance-row:before {
  content: "";
  display: block;
  position: absolute;
  border-top: 2px dashed #ccc;
  width: 80%;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0px auto;
}

/* popup for document open */
.Modaaal-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modaaal {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 80%;
  max-width: 740px;
  overflow-y: auto;
  position: relative;
  padding: 20px;
}

#uploadStudentDocuments .dashbord-table tr {
  border-bottom: 0 !important;
}

.Modaaal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.Modaaal-title {
  font-size: 20px;
  font-weight: bold;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

div center.w-full.my-10 {
  position: fixed;
  top: 0;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(0, 0, 0, .1);
}

.intake-filter {
  width: 100%;
}

.pendingDocAlert {
  border: 1px solid #ccc;
}

.pendingDocAlert h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.agent-table.border.student-table.table-overflow {
  overflow-y: unset;
}

.pendingDocAlert input[type="text"],
.pendingDocAlert input[type="file"] {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.pendingDocAlert input[type="file"] {
  border: none;
}

.pendingDocAlert .flex {
  justify-content: flex-end;
  margin-top: 20px;
}

.pop-form p.global-text {
  display: none;
}

.footer-content .custom-socail-links li a img {
  width: 45px;
  height: 45px;
}

.custom-socail-links li a {
  background: transparent !important;
  width: auto;
  height: auto;
}

ul.social-list {
  flex-wrap: wrap;
}

.custom-socail-links li a:hover {
  background: none !important;
  border: none !important;
}

.suggestion_dropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
  padding: 15px;
}

.suggestion_dropdown div {
  margin: 3px 2px;
}

.sug_value {
  display: flex;
  flex-direction: column;
}

a.sug_a {
  color: #000 !important;
  padding: 3px 8px;
}

a.sug_a:hover {
  background: #e7e7e7;
  border-radius: 3px;
}

span.sug_b {
  color: #787878 !important;
}

.suggestion_dropdown div:hover {
  background-color: #f5f5f5;
}

::-ms-input-placeholder {
  color: #b5b5b5 !important;
  font-weight: normal !important;
}

::placeholder {
  color: #b5b5b5 !important;
  font-weight: normal !important;
}

.inner-form-data input {
  border: 1px solid #919191 !important;
  border-width: 1px !important;
  border-radius: 3px !important;
  padding-block: 12px !important;
}

ul.head-sub-menus.absolute:before {
  content: "";
  position: absolute;
  top: -5px;
  left: 12px;
  background: #fff;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
}

.country-main-content .detail-part .inner-detail-part .cs-info {
  background: #fff;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: auto;
}

.main-contry-upr .country-main-content .detail-part .inner-detail-part .cs-info {
  padding: 0;
}

.country-main-content .detail-part .inner-detail-part h6 {
  padding-top: 10px;
}

.permissions.popup.employee-popup {
  overflow: visible;
  padding: 20px;
  max-width: 550px;
}

.permissions.popup.employee-popup h1 {
  background: #fff;
  color: #000;
  padding: 20px 0 10px;
  font-size: 25px;
}

.permissions.popup.employee-popup h1 svg {
  position: absolute;
  top: -15px;
  right: -15px;
  overflow: visible;
}

.permissions.popup.employee-popup button {
  border: 1px solid #059669;
  border-radius: 5px;
  color: #059669;
}

/* .search-content .right-part .right-part-content {
  width: 100%;
} */

/* .search-content .right-part .right-part-content .right-main {
  max-height: 441px;
  overflow-y: scroll !important;
} */

/* .search-content .right-part .right-part-content .close {
  right: -17px;
  z-index: 1;
  top: -17px;
} */

.agent-main .dash_bord_form .css-13cymwt-control div div,
.dash_bord_form .css-13cymwt-control div div,
.dash_bord_form .css-t3ipsp-control div div {
  min-width: fit-content !important;
  min-width: fit-content !important;
}

.filter-part .right-part  .css-t3ipsp-control {
  border: none !important;
}


.filter-part .right-part  .css-t3ipsp-control, .filter-part .right-part .css-t3ipsp-control div {
  max-height: 38px !important;
}

div[page="home"] p {
  font-weight: normal !important;
}

.side-program {
  position: fixed;
  right: -100%;
  top: 75px;
  background: #fff;
  width: 70%;
  border-left: 1px solid #949494;
  border-top-left-radius: 30px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  transition: 0.8s ease-in-out;
  padding-bottom: 95px;
}

.side-program.show-sidebar {
  right: 0;
  transition: 0.8s ease-in-out;
}

.side-program::-webkit-scrollbar {
  display: none;
}

.side-program .program-heading {
  background: #19632f;
  padding: 15px;
  margin-bottom: 20px;
  border-top-left-radius: 30px;
}

.side-program .program {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.side-program .program:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.custom-popcs div:last-child li {
  padding-bottom: 8rem;
}

.examLabels div input {
  width: 100%;
}

.admin-log .inner-form-data {
  height: fit-content;
}

.search-content {
  transition: height 0.8s ease-in-out;
}

.hide-filter {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.54);
}

.show-filter {
  height: 100vh;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.54);
}

.menuscrollheader-part nav {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.required-popup ul li span:nth-child(1) {
  min-width: 240px;
}

.new_pop.embassy-doc ul li span:nth-child(1) {
  min-width: 107px;
}

.createcollegeNamePopup ul li {
  border-bottom: 1px solid #c5c5c5;
}

.createcollegeNamePopup ul li:last-child {
  border-bottom: none;
}

.createcollegeNamePopup.documents.add_college_popup.new_pop div input,
.createcollegeNamePopup.documents.add_college_popup.new_pop div select,
.createcollegeNamePopup.documents.add_college_popup.new_pop ul .dropdown-container {
  border: 1px solid #c5c5c5 !important;
}

.admin-pass div.relative {
  position: relative;
}

.admin-pass div.relative button.eye-btn {
  position: absolute;
  top: 19px;
  bottom: 0;
  right: 10px;
  margin: auto;
  height: fit-content;
  background: none;
}

.admin-pass div.relative button.eye-btn svg {
  color: #b5b5b5;
  width: 18px;
  height: 18px;
}

.agent-table td:nth-child(1) {
  width: 31px;
}

.agent-table td td {
  width: fit-content !important;
}

.agent-main-form {
  background: #f9f9f9;
}

.agent-dashboard {
  padding: 0;
}

.agent-dashboard .agent-form {
  padding: 20px;
}

.agent-dashboard .agent-form h2 {
  font-size: 1.3rem;
}

.new-right .inner-form-data {
  width: 100%;
  max-width: 600px;
  background: #fff !important;
}

.new-right .inner-form-data .col-12 .space-y-6 {
  background: none;
  box-shadow: none;
}

.new-right .inner-form-data .col-12 .button button {
  width: 100%;
  background: #059669 !important;
  transition: 0.3s ease-in-out;
  border: 1px solid #059669;
  font-size: 1.2rem;
}

.pass-input {
  position: relative;
}

.pass-input button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  margin: auto;
}

.pass-input button svg {
  color: #b5b5b5;
  width: 20px;
  height: 20px;
}

.filter-heading div button {
  min-width: 150px;
}

.apply-heading {
  background: #059669;
  text-align: center;
  color: #fff;
  padding: 10px 20px;
}

.apply-heading h2 {
  font-size: 1.2rem;
}

.table-overflow {
  max-height: 400px;
  overflow-y: scroll;
  border: none !important;
  /* margin-top: 1rem; */
  margin: 0 !important;
  padding-bottom: 0 !important;
  margin-right: -5px !important;
}

.card-body.px-0.pt-0.pb-2.table-border {
  padding: 0;
}

/* .agent-table .table-overflow {
  margin-inline: 0.25rem;
} */

.table-overflow::-webkit-scrollbar {
  width: 5px;
}

.table-overflow::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
}

.table-overflow::-webkit-scrollbar:horizontal {
  height: 8px;
}

.enrolled-pop {
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.enrolled-pop .table-overflow {
  max-height: fit-content !important;
}

.enrolled-pop tbody {
  margin: 0 !important;
}

.enrolled-pop tbody tr.docRow {
  padding: 0.5rem !important;
}

.pendingDoctoast.popup.active {
  padding: 1.5rem;
  width: 100%;
  max-width: 550px;
}

.pendingDoctoast.popup.active .w-full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pendingDoctoast.popup.active .pendingDoctoast-content {
  display: flex;
  align-items: center;
}

#uploadStudentDocuments table {
  border-color: #e5e7eb !important;
}

.drop-flex .setting-container {
  width: fit-content;
}

div[heading_title="Agents List"] .flex.justify-center input {
  height: fit-content;
  max-width: 100%;
}

div[heading_title="Agents List"] .flex.justify-center button {
  margin-top: 0 !important;
  min-height: 37px;
}

.row.mt-10.p-45.mob-padding .my-3 button {
  margin-block: 0 !important;
  min-height: 37px;
  min-width: 60px;
}

.agentDetailsAsideBar .tabDetail td button.approve,
.agentDetailsAsideBar .tabDetail td button.decline {
  min-width: 80px;
}

.agent-tabs ul.nav-tabs {
  display: flex;
  margin-top: 1.5rem;
  border-bottom: 1px solid #e5e5e5;
}

.agent-tabs ul.nav-tabs li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.agent-tabs ul.nav-tabs li button {
  padding: 10px;
  font-size: 14px;
  color: #000;
  border-radius: 2px;
}

.agent-tabs ul.nav-tabs li button.active {
  background: #19632f !important;
  color: #fff;
}

.agent-tabs .tab-content .tab-pane {
  display: none;
}

.agent-tabs .tab-content,
.agent-tabs .tab-content .tab-pane.active {
  display: block;
}

.agent-dashboard .agent-form {
  background: #e7e9f5;
}

.DateRangePicker {
  width: 100%;
}

.DateRangePicker .DateRangePickerInput {
  justify-content: space-between !important;
}

.tab-main {
  padding: 0 15px;
}

.manage-table .table-overflow {
  margin: 0;
}

.manage-table .agent-table {
  border-radius: 10px;
}

div#aside .activeee i {
  color: #fff !important;
  background: #008000 !important;
}

div#aside .activeee-link p {
  color: #008000 !important;
}

div#aside .activeee-link .subItemIconDot {
  background: #008000 !important;
}

.menuscrollheader-part ul.header-menus li button.rounded-full a {
  border-bottom: none !important;
}

.flex.p-2.items-start.justify-center.relative form div.bg-gray-200 {
  margin-bottom: 5.5rem;
}

.profile-status {
  position: absolute;
  top: 16vh;
  width: fit-content;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.flex.p-2.items-start.justify-center.relative form label {
  line-height: 1.3;
  font-size: 0.75rem;
  margin-bottom: 5px;
}

.flex.p-2.items-start.justify-center.relative form .flex.flex-col {
  margin-bottom: 1.5rem;
  justify-content: space-between;
}

.flex.p-2.items-start.justify-center.relative form input,
.flex.p-2.items-start.justify-center.relative form select,
.flex.p-2.items-start.justify-center.relative form textarea {
  width: 100%;
  min-height: 37px;
}

.enrolled-files select option {
  background: #fff !important;
}

.agent-table td select option {
  background: #fff !important;
  color: #000 !important;
}

.multistep {
  background: #fff;
  box-shadow: 0px 0px 5px 0px #d8d6d6;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 2rem;
}

.multistep .input-container {
  margin-bottom: 1.5rem;
}

.multistep .input-container .examLabels {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-between;
}

.multistep .input-container .examLabels .input-container-child {
  display: flex;
  flex-direction: column;
  width: 46%;
}

.multistep .input-container .examLabels .input-container-child label {
  display: flex;
  margin-bottom: 8px;
}

.multistep .input-container input {
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 5px !important;
}

.multistep .input-container input,
.multistep .input-container select {
  min-height: 54px;
  max-height: 54px;
  outline: none;
}

.multistep .input-container .css-13cymwt-control div {
  padding-block: 0 !important;
  margin: 0 !important;
}

.multistep button {
  min-width: 180px;
  background: #059669;
  color: #fff;
  font-size: 15px;
  padding: 14px 12px;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 1.5rem;
  max-width: 500px;
}

/* 17/11/23*/

div#sure_approve .modal_inner.select-col-popup .header_modal h1 {
  font-size: 16px;
}

div#sure_approve .modal_inner.select-col-popup {
  position: fixed;
  top: 50%;
  width: 325px;
}

div#sure_decline .modal_inner.select-col-popup .header_modal h1 {
  font-size: 16px;
}

div#sure_decline .modal_inner.select-col-popup {
  position: fixed;
  top: 50%;
  width: 325px;
}

div#sure_approve {
  height: 100% !important;
}

div#sure_decline {
  height: 100% !important;
}

h2.filter-heading {
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #e9e9e9;
  color: #1c3479;
}

.filter-check-list {
  background: #fff;
  box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 10px;
}

.filter-check-list input {
  min-height: 27px !important;
}

.filter-check-list p {
  font-size: 11px;
  min-width: 130px;
}

.settings-tab .tab-content div form {
  background: #fff;
  box-shadow: 0px 0px 5px 0px #d8d6d6;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 2rem;
}

.settings-tab .tab-content div form button {
  background: #19632f;
  min-width: 20%;
  padding: 14px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-inline: auto;
}

.login-signup.new-main .side-image {
  display: none;
  border-radius: 20px 0 0 20px;
}

.footer-content ul .dropdown-content {
  height: 0;
  transition: 0.7s ease-in-out;
  opacity: 0;
}

.footer-content ul .dropdown-content a {
  margin-bottom: 5px;
  font-size: 14px;
}

.footer-content ul .dropdown-content a:last-child {
  margin-bottom: 0;
}

.footer-content ul .dropdown-content.drop-content-active {
  height: 60px;
  opacity: 1;
  transition: 0.7s ease-in-out;
}

.dropdown i {
  transform: rotate(0);
  transition: 0.7s ease-in-out;
}

.dropdown.drop-active i {
  transform: rotate(90deg);
  transition: 0.7s ease-in-out;
}

.login-signup .new-right .inner-form-data .admin_logo,
.new-main.login-signup .signup-login .login-form .log-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-inline: auto;
}

.login-signup .new-right .inner-form-data main.main-content input {
  max-width: 100%;
}

/* .search-content .right-part .right-part-content .right-main select {
  background: #fff !important;
} */

.filter-part.right-show .right-part .right-part-content .w-full.md\:w-1\/3.md\:px-3.md\:mb-5:nth-child(4) input,
.filter-part.right-show .right-part .right-part-content .college_type input {
  min-height: unset;
}

@media (min-width: 423px) {
  .profile-status {
    top: 12.5vh;
  }
}

@media (min-width: 550px) {
  .apply_box {
    width: 48%;
  }
}

@media (min-width: 700px) {

  .inner-form-data .main_flex_box label,
  .inner-form-data .main_flex_box .ml-auto div {
    font-size: 0.8rem;
  }

  .filter-part.right-show .right-part .right-part-content .w-full.md\:w-1\/3.md\:px-3.md\:mb-5:nth-child(3) {
    width: 20% !important;
  }

  .filter-part.right-show .right-part .right-part-content .w-full.md\:w-1\/3.md\:px-3.md\:mb-5:nth-child(4) {
    width: 46% !important;
  }

  .filter-part.right-show .right-part .right-part-content .w-full.md\:w-1\/3.md\:px-3.md\:mb-5:nth-child(4) .mb-4.w-\[150px\] {
    width: 48%;
  }
}

@media (min-width: 768px) {
  .home-hero h1 {
    line-height: 1.8;
    padding: 60px 0px;
  }

  div[page="home"] .inner-levels p {
    font-size: 0.875rem !important;
  }

  .programs-list.side-program .duration-list p strong {
    min-height: 42px;
  }

  .examLabels div {
    width: 33%;
  }

  .begining-part h3 {
    font-size: 2.25rem;
  }

  .query-filter input,
  .query-filter .block.w-full {
    width: 100%;
    min-width: 32%;
    max-width: 32%;
    border-radius: 4px;
  }

  .query-filter .query-button button {
    border-radius: 5px !important;
    min-width: 100px;
  }

  .agent-tabs ul.nav-tabs {
    padding: 0 20px;
  }

  .agent-tabs ul.nav-tabs li button {
    padding: 10px 18px;
    font-size: 1rem;
  }

  .drop-flex .block.w-full.flex-1,
  .drop-flex .css-13cymwt-control {
    min-width: 145px;
    max-width: 145px !important;
  }

  .tab-main {
    padding: 0 20px;
  }

  .apply_box {
    width: 32%;
  }

  .row.addCountryPage.flex.flex-row input.college-input {
    border-radius: 0.375rem;
    min-height: 40px;
  }

  .profile-status {
    top: 13px;
    right: 0;
    width: 21%;
  }

  .settings-form input {
    min-height: 48px;
  }

  .multistep {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .multistep .input-container {
    width: 32%;
    margin-inline: 0 !important;
  }

  .login-signup.new-main .side-image {
    display: flex;
    width: 50%;
  }

  .login-signup .new-right {
    background-image: none;
  }

  .login-signup .new-right .inner-form-data {
    max-width: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    border-radius: 0 20px 20px 0;
  }

  .login-signup .new-right .rt-inner.w-full {
    display: flex;
    height: 100vh;
  }

  .login-signup .new-right .rt-inner.w-full .inner-form-data .admin_log {
    position: unset;
  }

  .login-signup.new-main .side-image img {
    object-fit: cover;
    object-position: center;
    border-radius: 20px 0 0 20px;
  }

  .login-form .rt-inner.w-full {
    padding: 2.5rem;
    background: #cbcbcb;
  }
}

@media (min-width: 891px) {
  .profile-status {
    top: 24px;
  }
}

@media (min-width: 992px) {
  .programs-list.side-program .duration-list p strong {
    min-height: unset;
  }

  .inner-form-data {
    min-height: fit-content;
  }

  .agent-tabs ul.nav-tabs {
    padding: 0;
  }

  ul.header-menus li {
    padding: 0;
  }

  .all_applications {
    justify-content: flex-start;
    gap: 2%;
  }

  .apply_box {
    width: 15%;
    margin-bottom: 1.5rem;
  }

  h2.filter-heading {
    font-size: 32px;
    padding-left: 0.75rem;
  }
}

@media (min-width: 1024px) {
  .multistep .input-container .examLabels .input-container-child {
    width: 20%;
  }
}

@media (min-width: 1025px) {
  .country-main-content .detail-part .inner-detail-part h6 {
    min-height: 62px;
    line-height: 1.6rem;
  }

  .permissions.popup.employee-popup h1 {
    font-size: 25px;
  }

  div[page="home"] p {
    font-size: 16px !important;
  }

  div[page="home"] .inner-levels p {
    font-size: 0.875rem !important;
  }

  .home-hero h1 {
    line-height: 1.4;
  }

  .right-form {
    margin-left: 20px;
  }

  .filter-part.right-show .right-part {
    height: 580px;
  }

  /* .search-content .right-part .right-part-content {
    margin: 5px auto;
    width: 98%;
  } */

  /* .search-content .right-part .right-part-content .right-main {
    max-height: unset;
    overflow: unset;
  } */

  .filter-part .right-part label {
    min-width: 60px;
  }

  .enrolled-files.agent-table td:nth-child(10) button {
    min-width: 100px;
  }

  .student-inner-table td,
  .enrolled-list-table td,
  .agent-commission-table td,
  .assesment-forms-table td {
    min-width: unset;
  }

  .drop-flex .block.w-full.flex-1,
  .drop-flex .css-13cymwt-control {
    min-width: 250px;
    max-width: 250px !important;
  }

  .custom_add_button.input-button {
    min-width: 80px;
  }

  .college-input {
    min-width: 177px;
    max-width: 177px;
  }
}

@media (min-width: 1200px) {
  .new-right .inner-form-data {
    padding-inline: 3rem;
  }

  .login-form .rt-inner.w-full {
    padding: 3.5rem;
  }
}

@media (min-width: 1240px) {
  /* .search-content .right-part .right-part-content {
    width: 95%;
  } */

  .filter-part.right-show .right-part {
    height: 600px;
  }

  /* .search-content .right-part .right-part-content .right-main {
    max-height: 600px;
  } */

  .country-main-content .detail-part .inner-detail-part h6 {
    min-height: 87px;
  }

  .right-form p.mb-4 {
    font-size: 14px;
  }

  .college-input {
    min-width: 200px;
    max-width: 200px;
  }
}

@media (min-width: 1300px) {

  .home-hero ul li,
  .courses-part ul.react-tabs__tab-list li,
  .begining-part button,
  ul.header-menus li a {
    font-size: 16px;
  }

  .home-hero .slick-slide p {
    font-size: 16px !important;
  }

  .custom-popcs div:last-child li {
    padding-bottom: 5rem;
  }

  .begining-part h3 {
    font-size: 2.75rem;
  }
}

@media (min-width: 1440px) {
  div[page="home"] p {
    font-size: 18px !important;
  }

  .footer-content p,
  .footer-content ul li a,
  .footer-content ul li div.dropdown {
    font-size: 16px !important;
  }

  .login-form .rt-inner.w-full {
    padding: 4.5rem;
  }
}

@media (max-width: 1300px) {

  span.placeholder,
  .login_method_btns button,
  .custom-field.one input,
  .inner-form-data button.log-btn,
  .inner-form-data .main-content .flex.gap-2 .ml-auto a,
  button#google_login_a,
  .inner-form-data .main-content p.mt-5.text-center.text-sm,
  .inner-form-data .main-content p.mt-5.text-center.text-sm p {
    font-size: 0.75rem;
  }

  .login_method_btns button {
    padding: 8px 10px;
  }

  .inner-form-data .admin_logo.sign_up a img {
    width: 8rem !important;
  }

  .inner-form-data button.log-btn {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  button#google_login_a {
    padding: 0.5rem !important;
  }

  button#google_login_a img {
    width: 18px;
  }

  .inner-form-data .icon.icon-eye {
    top: -30px;
  }
}

@media (max-width: 1199px) {
  .box-tab {
    display: flex;
    flex-direction: column;
  }

  .btm-row:last-child .box-mob .part2-row {
    background: transparent;
    border: none;
  }

  .mob-border {
    border: 1px solid #000 !important;
    border-radius: 50px !important;
    width: 100%;
    margin: 20px 0px 10px;
  }

  .mob-bg {
    background: #000;
    border-radius: 50px !important;
    width: 100%;
    margin: 10px 0px;
  }

  .part2-row {
    width: 100%;
  }

  .admin-side {
    position: absolute !important;
    height: 57rem !important;
  }
}

@media (max-width: 1024px) {
  .modal_inner {
    width: 95% !important;
  }

  .abt-upr:before {
    content: none;
  }

  .top-bar .lg\:grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .box-mob {
    display: flex;
    flex-direction: column;
  }

  .mob-border {
    border: 1px solid #000 !important;
    border-radius: 50px !important;
    width: 100%;
    margin: 20px 0px 10px;
  }

  .mob-bg {
    background: #000;
    border-radius: 50px !important;
    width: 100%;
    margin: 10px 0px;
  }

  .cs-box {
    display: flex;
  }

  .home-hero .slick-prev {
    left: 0px !important;
  }

  .home-hero .slick-next {
    right: 0px !important;
  }

  .top-bar .lg\:my-3.lg\:my-0,
  .top-bar .flex.items-center.justify-center.gap-4.pl-4.mb-5.lg\:mb-0.md\:mb-0 {
    display: none;
  }

  .right-form {
    position: static;
  }

  .box-mm {
    display: flex;
    flex-direction: row;
  }

  .add_college_popup.new_ccs .modal_inner {
    width: 500px !important;
  }

  .mdslides img {
    width: 100%;
  }

  img.crl-img {
    top: -90px;
    left: 0;
  }

  .modal_inner {
    width: 60% !important;
    max-width: 450px;
  }

  .all_applications {
    grid-template-columns: repeat(3, 1fr);
  }

  .DateInput {
    width: 105px !important;
  }
}

@media (max-width: 991px) {
  .top_tabs {
    padding: 3px 5px;
    margin: 0px 2px;
    font-size: 12px;
  }

  .box-mob {
    display: flex;
    flex-direction: column;
  }

  .btm-row:last-child .box-mob .part2-row {
    background: transparent;
    border: none;
  }

  .part2-row {
    width: 100%;
  }

  .mob-border {
    border: 1px solid #000 !important;
    border-radius: 50px !important;
    width: 100%;
    margin: 20px 0px 10px;
  }

  .mob-bg {
    background: #000;
    border-radius: 50px !important;
    width: 100%;
    margin: 10px 0px;
  }

  .cs-box {
    display: flex;
  }

  a.logo {
    max-width: 150px;
    height: 55px;
    overflow: hidden;
  }

  ul.header-menus li {
    padding: 10px 0px;
    margin: 0px 8px !important;
  }

  .mob-margin {
    margin: 0px;
  }

  .show-btn {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .innercollege {
    height: 100%;
  }

  .addmision_process {
    width: 100% !important;
  }

  h4.apply_for {
    font-size: 60px;
  }

  .addmision_process h2 {
    font-size: 42px;
  }

  .admin-side {
    position: absolute !important;
    height: 62rem !important;
  }
}

@media (max-width: 800px) {
  .row.addCountryPage.flex.flex-row.justify-start .lg\:w-9\/12.xs\:w-12\/12.my-4.p-4 {
    width: 100%;
  }

  .permissions.popup {
    width: 85%;
    word-break: break-all;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .setting_icon .grid div.mb-4 {
    width: 150px !important;
  }

  .setting_icon .addscl-cs button {
    min-width: 97px;
  }

  .top_tabs {
    font-size: 10px;
  }

  .dash_bord_form .pb-2 .w-full.md\:mb-0.mb-2,
  .dash_bord_form .pb-2 .w-full.md\:mt-0.mt-3 {
    width: 20%;
    max-width: 20%;
  }

  .dash_bord_form .apply_buttn {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  ul.header-menus li a {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .mob-padding {
    padding: 10px;
  }

  .setting_icon {
    display: flex;
    flex-direction: column;
  }

  .setting_icon .grid div.mb-4 {
    width: 100% !important;
  }

  .cs-flex .w-6\/12 {
    width: 100%;
    margin: 0px 0px 10px;
  }

  .mob-margin-0 {
    margin: 0px;
  }

  .cs-flex {
    display: flex;
    flex-direction: column;
  }

  /* 19/9/23 */
  .new-right {
    padding: 85px 20px;
    min-height: 100vh !important;
    max-height: 100vh;
    overflow: hidden;
  }

  .DateInput {
    width: 100% !important;
  }

  .dropZone {
    height: 250px;
    width: 100%;
  }

  .all_applications {
    grid-template-columns: repeat(2, 1fr);
  }

  .admin_log {
    top: -52px !important;
  }

  .admin_log img {
    width: 80px !important;
  }

  .cs_ccck {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .cs_bg {
    padding: 0 !important;
  }

  .main_flex_box {
    flex-wrap: wrap;
    gap: 0px !important;
  }

  .detail-part {
    grid-template-columns: 100% !important;
  }

  .coverleft {
    width: 100%;
  }

  .custom-dis-page {
    width: 100%;
  }

  .country-main-content h1 {
    font-size: 30px;
  }

  .profile-flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px !important;
  }

  .right-profile {
    width: 100%;
    padding: 0;
  }

  .showmenu {
    display: block;
    background: #fff;
    text-align: left;
  }

  .showmenu ul.header-menus li {
    width: 100%;
    text-align: left;
    padding: 5px 10px;
  }

  .showmenu ul.header-menus li a {
    color: #000 !important;
    font-size: 0.875rem;
  }

  .top-bar .mb-5 {
    margin: 0;
  }

  .top-bar {
    padding: 15px 10px;
  }

  .home-hero {
    padding-top: 175px;
  }

  .begining-part h3 {
    font-size: 30px;
  }

  .showmenu ul.header-menus li:last-child a {
    color: #fff !important;
  }

  .levels h4 {
    font-size: 35px;
    line-height: 1.4;
  }

  .part2-row button {
    font-size: 10px;
  }

  .planepart {
    display: none;
  }

  input#exampleSearch {
    width: 100%;
  }

  .part2-row {
    width: 55%;
  }

  .slick-next {
    right: 0 !important;
  }

  .courses-part .box {
    width: 100%;
    margin-bottom: 20px;
  }

  .courses-part ul.react-tabs__tab-list li {
    padding: 8px 20px;
    font-size: 13px;
  }

  .left-log {
    min-height: 110px;
  }

  .logo-side {
    position: static;
  }

  .left-log .slick-slider.slick-initialized {
    max-width: 100%;
  }

  .left-log .slick-dots {
    padding-bottom: 20px;
  }

  .left-log {
    overflow-x: hidden;
  }

  .ryt-log .form-login {
    max-width: 100%;
  }

  .loginTabs {
    max-width: 100%;
  }

  main.ml-\[255px\] {
    margin-left: 0;
  }

  .table-responsive {
    overflow-x: scroll;
  }

  .new-left {
    min-height: auto;
  }

  .new-right {
    min-height: auto;
  }

  .new-right h2 {
    font-size: 35px;
  }

  .modal_inner {
    width: 95% !important;
  }

  .button-part button {
    padding: 10px;
  }

  .select-col-popup div {
    margin: 0 0 5px 0;
  }

  .right-form {
    position: static;
  }

  .coverleft {
    position: static;
  }

  .bottm-headcont {
    position: static;
    width: 100%;
    margin-bottom: 30px;
  }

  .high-width {
    width: 100%;
  }

  .low-width {
    width: 100%;
  }

  .inn-main {
    width: 100%;
    margin-bottom: 10px;
  }

  .inn-main p {
    font-size: 16px;
  }

  .country-video iframe {
    width: 100%;
  }

  .country-banr h1 {
    font-size: 30px;
  }

  .dashboardItem {
    margin: 0 0 10px;
    width: 100%;
  }

  .dashboardItemsList {
    display: block;
    padding: 0px 10px;
    margin: 30px 0px 0px;
  }

  .home-hero .slick-prev {
    left: 0;
  }

  .home-hero .slick-next {
    right: 0px !important;
  }

  .home-hero h1 {
    line-height: normal;
  }

  .home-hero h1 {
    padding: 0;
  }

  h4.apply_for {
    font-size: 36px;
  }

  .discover-cont {
    flex-wrap: wrap;
  }

  .custom-img {
    width: 100% !important;
  }

  .custom-img img {
    height: auto !important;
    width: 100% !important;
  }

  .admission-part {
    padding: 0;
  }

  .courses-part {
    padding: 50px 0px;
  }

  .custom-margin {
    margin: 0px;
  }

  .showmenu {
    display: block;
    background: #fff;
    text-align: left;
  }

  .showmenu ul.header-menus li {
    width: 100%;
    text-align: left;
    padding: 5px 10px;
  }

  .showmenu ul.header-menus li a {
    color: #000 !important;
  }

  .top-bar .mb-5 {
    margin: 0;
  }

  .top-bar {
    padding: 15px 10px;
  }

  .home-hero {
    padding-top: 175px;
  }

  .begining-part h3 {
    font-size: 30px;
  }

  .showmenu ul.header-menus li:last-child a {
    color: #fff !important;
  }

  .levels h4 {
    font-size: 35px;
  }

  .part2-row button {
    font-size: 10px;
  }

  .planepart {
    display: none;
  }

  input#exampleSearch {
    width: 100%;
  }

  /* .part2-row {
  width: 55%;
} */

  .slick-next {
    right: 0 !important;
  }

  .courses-part .box {
    width: 100%;
    margin-bottom: 20px;
  }

  .courses-part ul.react-tabs__tab-list li {
    padding: 8px 15px;
    font-size: 12px;
  }

  .home-hero h1 {
    line-height: normal;
  }

  .left-log {
    min-height: 110px;
  }

  .logo-side {
    position: static;
  }

  .left-log .slick-slider.slick-initialized {
    max-width: 100%;
  }

  .left-log .slick-dots {
    padding-bottom: 20px;
  }

  .left-log {
    overflow-x: hidden;
  }

  .ryt-log .form-login {
    max-width: 100%;
  }

  .loginTabs {
    max-width: 100%;
  }

  main.ml-\[255px\] {
    margin-left: 0;
  }

  .table-responsive {
    overflow-x: scroll;
  }

  .new-left {
    min-height: auto;
  }

  .new-right {
    min-height: auto;
  }

  .new-right h2 {
    font-size: 35px;
  }

  .modal_inner {
    width: 80% !important;
  }

  .button-part button {
    padding: 10px;
  }

  .select-col-popup div {
    margin: 0 0 5px 0;
  }

  .right-form {
    position: static;
  }

  .coverleft {
    position: static;
  }

  .bottm-headcont {
    position: static;
    width: 100%;
    margin-bottom: 30px;
  }

  .high-width {
    width: 100%;
  }

  .low-width {
    width: 100%;
  }

  .inn-main {
    width: 100%;
    margin-bottom: 10px;
  }

  .inn-main p {
    font-size: 16px;
  }

  .country-video iframe {
    width: 100%;
  }

  .country-banr h1 {
    font-size: 30px;
  }

  .dashboardItem {
    margin: 0 0 10px;
    width: 100%;
  }

  .dashboardItemsList {
    display: block;
    padding: 0px 10px;
    margin: 30px 0px 0px;
  }

  img.closebar-btn {
    display: none;
    z-index: 9;
    border-radius: 50px;
    height: 30px;
    width: 30px;
    padding: 1px;
  }

  .hidebar {
    transition: 0.4s !important;
    left: -1000px !important;
  }

  .search-main {
    position: static;
    display: block;
  }

  img.un-logo {
    width: 100px;
    height: 100px;
    margin: 0px auto 20px;
  }

  .inner-course div {
    width: 20%;
    display: inline-block;
    margin: 0 8px 0px 0px;
  }

  .inner-course input {
    width: 100%;
  }

  ul.head-sub-menus {
    width: 100%;
    left: 0;
  }

  .formline {
    display: block;
  }

  .formline div {
    display: block;
  }

  .formline button {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .formline input {
    width: 100%;
    margin-bottom: 15px;
  }

  .chance-row:before {
    display: none;
  }

  ul.view-tab-content a {
    font-size: 15px;
    padding: 10px 20px;
  }

  ul.view-tab-content {
    justify-content: center;
  }

  .partner ul.slick-dots {
    display: none !important;
  }

  .courses-part h3,
  .about-part h2 {
    font-size: 30px;
  }

  .courses-part .container {
    padding: 0px;
  }

  .about-part h2 {
    font-size: 30px;
    text-align: center;
  }

  .about-part p {
    text-align: center;
    margin-bottom: 20px;
  }

  .about-part a {
    margin: 20px auto;
  }

  .custom-body {
    width: 90%;
    max-width: 495px;
  }

  .cs-box {
    display: block;
  }

  .top-bar .lg\:grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .front-cont {
    display: flex;
    flex-direction: column;
  }

  .cs-view-img {
    width: 100%;
    margin-bottom: 20px;
  }

  .cs-view-data {
    width: 100%;
  }

  .elig-btn {
    width: 100%;
    margin: 10px 0px;
  }

  .add_college_popup .span {
    font-size: 20px;
  }

  .home-width {
    width: 100%;
  }

  .dash_bord_form {
    padding: 10px;
  }

  .row.addCountryPage .flex.gap-2 {
    width: 100%;
  }

  .row.addCountryPage .flex.gap-2 input,
  .row.addCountryPage .flex.gap-2 button {
    width: 50%;
  }

  .row.addCountryPage .flex.gap-2 button {
    max-width: fit-content;
    padding: 10px 24px;
  }
}

@media (max-width: 700px) {
  .open-data {
    width: 70% !important;
    transform: translate(-60%, -50%) !important;
  }

  .header-tabs {
    max-width: 150px;
    overflow-x: scroll;
  }

  .header-tabs .top_tabs {
    min-width: fit-content;
    display: flex;
    align-items: center;
  }

  .admin-side {
    position: absolute !important;
    height: 69rem !important;
  }
}

@media (min-width: 320px) and (max-width: 639px) {
  .admin-side {
    position: absolute !important;
    height: 93rem !important;
  }
}

@media (max-width: 575px) {
  .add_college_popup.new_ccs .modal_inner {
    width: 280px !important;
  }

  .all_applications {
    grid-template-columns: repeat(1, 1fr);
  }

  img.crl-img {
    top: -80px;
    left: 0;
  }

  .add_college_popup .span {
    font-size: 20px;
  }

  .createcollegeNamePopup.add_college_popup.active {
    width: 70%;
  }

  .marketing-check {
    flex-wrap: wrap;
  }

  .marketing-check .flex.w-auto {
    width: 29%;
    text-align: center;
  }
}

@media (max-width: 475px) {
  .admin_logo img {
    width: 150px !important;
  }

  .createcollegeNamePopup.add_college_popup.active {
    width: 90%;
  }
}

@media (max-width: 411px) {
  .row.addCountryPage.flex.flex-row input.college-input {
    min-height: 58px;
  }
}

@media (max-width: 375px) {
  h4.apply_for {
    font-size: 24px;
    line-height: 31px;
  }

  .main_flex_box {
    justify-content: center !important;
  }
}

@media (max-width: 314px) {
  .main_flex_box .cs_bg.mx-0.p-2.px-4 {
    margin-top: 6px;
  }
}

body {
  font-family: "Poppins", sans-serif;
}

.enabled-button {
  cursor: pointer;
}

.disabled-button {
  cursor: not-allowed;
}

.popup_z_index {
  z-index: -1;
}

@media (max-width: 767px) {
  .flex.justify-between.header-tabs {
    display: none;
  }
}