.overlay-open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 90vh;
  overflow: hidden;
  z-index: 99999999;
  background: white;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.popup::-webkit-scrollbar,
.popup::-webkit-scrollbar-track {
  width: 0;
}

.pop-form {
  background: #fff;
  text-align: left;
  border: 1px solid #ced4da;
  position: sticky;
  top: 0px;
  box-shadow: 0px 0px 5px 0px #d8d6d6;
  margin: 0;
  border-radius: 0px;
}
.pop-form p {
  font-size: 120%;
  color: #000;
}

.pop-form h3 {
  font-size: 20px;
  text-align: center;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  font-weight: 600;
  padding-top: 0;
  background: #333333;
  color: #fff;
  margin-left: -17px;
  margin-right: -17px;
  margin-top: -17px;
  margin-bottom: 13px;
  padding: 14px;
}

.popup.pop-form input {
  border-radius: 5px;
  margin-bottom: 15px;
}

p.global-text input[type="checkbox"] {
  margin: 0 10px 0px 0px;
}

.popup.pop-form .cursor-pointer {
  font-size: 20px;
  border: 1px solid;
  width: 30px;
  border-radius: 50px;
  height: 30px;
  line-height: 27px;
  right: 10px;
  top: 10px;
  position: absolute;
}

.pop-form input {
  width: calc(100% - 20px);
  height: 40px;
  margin: 5px 0px;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
}

p.global-text a {
  color: #059669;
}

.pop-form select {
  width: calc(100% - 20px);
  height: 40px;
  padding: 0px 10px;
  border-radius: 5px;
  margin: 5px 0px;
  border: 1px solid #ced4da;
  font-size: 110%;
}

p.global-text input {
  width: auto;
}

p.global-text {
  display: flex;
  align-items: center;
  margin: 10px;
}

p.global-text a {
  margin: 0;
}

.pop-form button {
  width: calc(100% - 20px);
  background: #059669;
  color: #fff;
  padding: 8px 0px;
  border-radius: 0px;
  margin: 10px;
  font-size: 18px;
}
.pop-form button:hover {
  background-color: #333;
}
.prefer {
  margin-left: 12px;
}
